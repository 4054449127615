import { useTheme } from '@mui/material';
import Input from '@mui/material/Input';
import EmailIcon from '../../assets/icons/email_icon';
import { AppColors } from '../../theme/AppTheme';
import InputErrorText from './InputErrorText';


interface EmailInputProps {
    value: string;
    error?: string;
    hideIcon?: boolean;
    hidden?: boolean;
    disabled?: boolean;
    onValueChanged: (value: string) => void;
    onEnterPressed?: () => void;
}

const EmailInput = (props: EmailInputProps) => {
    const theme = useTheme();

    return (
        <div style={{ display: props.hidden ? 'none' : 'flex', flexDirection: 'column', width: '100%', height: '75px' }}>
            <Input
                disableUnderline
                id="outlined-search"
                type="email"
                name="email" // Added name attribute for form recognition
                error={!!props.error}
                autoComplete="username" // Changed from 'email' to 'username' for login forms
                disabled={props.disabled}
                sx={{
                    backgroundColor: AppColors.white,
                    borderRadius: '12px',
                    minHeight: '60px',
                    width: '100%',
                    paddingLeft: props.hideIcon ? '6px' : '18px',
                    border: `1px solid ${props.error ? AppColors.error : AppColors.grey.border}`,
                }}
                inputProps={{
                    style: {
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        ...theme.typography.h5,
                        color: AppColors.black,
                    }
                }}
                placeholder='Enter your email'
                startAdornment={
                    props.hideIcon ? undefined : <EmailIcon />
                }
                value={props.value}
                onChange={(e) => props.onValueChanged(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        if (props.onEnterPressed)
                            props.onEnterPressed();
                    }
                }}
            />
            {props.error && <InputErrorText variant='caption'>{props.error}</InputErrorText>}
        </div>
    )
}

export default EmailInput