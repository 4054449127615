import styled from '@emotion/styled';
import { Box, FilledInput, FormControl, InputAdornment, Typography, useTheme } from '@mui/material';
import { ChangeEvent, forwardRef, useEffect, useRef, useState } from 'react';
import MinusIcon from '../../assets/icons/minus_icon';
import PlusIcon from '../../assets/icons/plus_icon';
import SearchIcon from '../../assets/icons/search_icon';
import NavigationConstants from '../../navigation/NavigationConstants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setDrawerIsOpen } from '../../redux/reducers/appReducer';
import { clearSelectedSavedResource } from '../../redux/reducers/resourceReducer';
import { clearSelectedJob, setSelectedJob } from '../../redux/reducers/savedJobsReducer';
import { clearSelectedResume } from '../../redux/reducers/savedResumeReducer';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import SearchClearButton from '../../shared/components/SearchClearButton';
import { StyledBackButton } from '../../shared/components/SideBarButtons';
import Job from '../../shared/models/Job';
import { AppColors, sharpTransition } from '../../theme/AppTheme';
import SavedJobCard from './SavedJobCard';
import SavedJobsSearchView from './SavedJobsSearchView';

const Sidebar = styled(Box)((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'clip',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderRight: `2px solid ${AppColors.grey.light}`,
    height: '100%',
    width: props.isMobile ? '100%' : NavigationConstants.sidebarWidth,
    backgroundColor: AppColors.white,
}));

const Header = styled(Box)((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '24px',
    paddingRight: '16px',
    marginTop: props.isMobile ? '72px' : '46px',
    width: props.isMobile ? 'calc(100% - 40px)' : '100%',
}));

const SearchInput = styled(FormControl)(() => ({
    width: '100%',
}));

const SearchDiv = styled(Box)({
    width: '100%',
    position: 'sticky',
    top: '60px',
    marginTop: '46px',
    marginBottom: '34px',
    paddingBottom: '12px',
    backgroundColor: AppColors.white,
});

const StyledInput = styled(FilledInput)((props: { shrink: boolean }) => ({
    marginLeft: '24px',
    marginRight: '24px',
    borderRadius: '8px',
    height: props.shrink ? '38px' : '48px',
    backgroundColor: AppColors.grey.lightest,
    '&:hover': {
        backgroundColor: AppColors.grey.light
    },
    transition: 'height 0.2s ease-in-out',
}));

const SavedJobsSidebarView = () => {
    const [titleOpacity, setTitleOpacity] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [shrinkSearch, setShrinkSearch] = useState(false);

    const isMobile = useAppSelector(state => state.appState.isMobile);

    const searchbarRef = useRef<HTMLDivElement | null>(null);
    const sidebarRef = useRef<HTMLDivElement | null>(null);

    const handleScroll = () => {
        if (sidebarRef.current) {
            const scrollPosition = sidebarRef.current.scrollTop;
            if (scrollPosition > 25 && titleOpacity === 1) {
                setTitleOpacity(0);
            } else if (scrollPosition <= 25 && titleOpacity === 0) {
                setTitleOpacity(1);
            }
        }
        if (searchbarRef.current) {
            const scrollPosition = searchbarRef.current.getBoundingClientRect().top;
            if (!shrinkSearch && scrollPosition === 60) {
                setShrinkSearch(true);
            } else if (shrinkSearch && scrollPosition > 60) {
                setShrinkSearch(false);
            }
        }
    }

    return (
        <Sidebar isMobile={isMobile} ref={sidebarRef} onScroll={handleScroll}>
            {isMobile && <StyledBackButton />}
            {isMobile &&
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', zIndex: 9, position: 'absolute', left: 0, right: '12px', top: 0, height: '60px', backgroundColor: AppColors.white }}>
                    <Typography variant='h4' style={{ marginLeft: '60px', marginTop: '8px', fontSize: '18px', opacity: 1 - titleOpacity, transition: `opacity 0.2s ${sharpTransition}` }}>
                        Saved Jobs
                    </Typography>
                </div>}
            <Header isMobile={isMobile}>
                <Typography variant='h4' style={{ fontSize: isMobile ? '18px' : '14px', opacity: titleOpacity, transition: `opacity 0.2s ${sharpTransition}` }}>
                    Saved Jobs
                </Typography>
            </Header>
            <SearchTextInput searchTerm={searchText} onSearchChanged={(newText) => setSearchText(newText)} ref={searchbarRef} shrink={shrinkSearch} />
            {!searchText && <JobList />}
            {searchText && <SavedJobsSearchView searchText={searchText} />}
        </Sidebar>
    )
}

const ScrollingJobList = styled(Box)((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    overflowY: props.isMobile ? undefined : 'auto',
    flexGrow: 1,
    width: props.isMobile ? '100%' : NavigationConstants.sidebarWidth,
}));

const startingObject = (savedResumes: { [key: string]: Job[] }) => {
    let obj: { [key: string]: boolean } = {};
    Object.keys(savedResumes).forEach(element => {
        obj[element] = true;
    })

    return obj;
}

const JobList = () => {
    const dispatch = useAppDispatch();
    const isMobile = useAppSelector(state => state.appState.isMobile);
    const savedJobs = useAppSelector((s) => s.savedJobsState.jobs);
    const jobBuckets = useAppSelector((s) => s.savedJobsState.jobBuckets);
    const selectedJob = useAppSelector(state => state.savedJobsState.selectedJob);
    const jobToggle = useAppSelector((s) => s.savedJobsState.savedJobsToggle);
    const [open, setOpen] = useState<{ [key: string]: boolean }>(startingObject(savedJobs));

    useEffect(() => {
        dispatch(clearSelectedJob());
    }, []);

    useEffect(() => {
        setOpen({ ...open, 'Today': true });
    }, [jobToggle]);

    const cardSelected = (bucket: string, index: number) => {
        const job = savedJobs[bucket][index];
        dispatch(setSelectedJob(job));
        if (isMobile) {
            dispatch(clearSelectedResume());
            dispatch(clearSelectedSavedResource());
            dispatch(setDrawerIsOpen(false));
        }
    }

    const toggleSection = (bucket: string) => {
        setOpen({ ...open, [bucket]: !(open[bucket] ?? false) });
    }

    const isOpenBucket = (bucket: string): boolean => {
        return open[bucket] ?? false;
    }

    const jobBucketCards = (bucket: string) => {
        const isOpen = isOpenBucket(bucket);
        return (
            <>
                <ClickableOpacityDiv onClick={() => toggleSection(bucket)} style={{ maxWidth: 'calc(100% - 48px)', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '36px', marginBottom: isOpen ? '15px' : '0px', marginLeft: '24px', marginRight: '24px' }}>
                    <div>{bucket} ({savedJobs[bucket].length})</div>
                    {isOpen ? <MinusIcon /> : <PlusIcon />}
                </ClickableOpacityDiv>
                <div style={{
                    overflow: isOpen ? undefined : 'hidden',
                    height: isOpen ? 'auto' : '0px',
                }}>
                    {savedJobs[bucket].map((job, index) => <SavedJobCard key={index} onClick={() => cardSelected(bucket, index)} job={job} selected={job.id === selectedJob?.id} />)}
                </div>
            </>
        );
    }

    return (
        <ScrollingJobList isMobile={isMobile}>
            {jobBuckets.map((bucket) => {
                if (savedJobs[bucket] === undefined) {
                    return null;
                }
                return jobBucketCards(bucket);
            })}
        </ScrollingJobList>
    );
}

interface SearchTextInputProps {
    searchTerm: string;
    onSearchChanged: (newSearch: string) => void;
    shrink: boolean;
}

const SearchTextInput = forwardRef((props: SearchTextInputProps, ref) => {
    const { searchTerm, onSearchChanged, shrink } = props;
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const handleInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const newSearch = event.target.value;
        onSearchChanged(newSearch);
    }

    return (
        <SearchDiv
            ref={ref} >
            <SearchInput variant='filled'>
                <StyledInput
                    shrink={shrink}
                    disableUnderline
                    hiddenLabel
                    id="outlined-basic"
                    type='text'
                    startAdornment={
                        <InputAdornment sx={{ width: '18px', height: '18px', margin: 0, padding: 0, marginRight: '12px' }} position="end">
                            <SearchIcon />
                        </InputAdornment>
                    }
                    endAdornment={searchTerm.length > 0 &&
                        <SearchClearButton onClick={() => onSearchChanged('')} />
                    }
                    placeholder='Search'
                    onChange={handleInputChanged}
                    value={searchTerm}
                    sx={{
                        ...theme.typography.h5,
                        '&.Mui-focused': {
                            backgroundColor: AppColors.grey.light
                        },
                        '& input::placeholder': {
                            ...theme.typography.h4,
                            color: AppColors.grey.dark,
                            opacity: 1.0,
                        },
                        input: {
                            ...theme.typography.h4,
                        },
                    }}
                />
            </SearchInput>
        </SearchDiv >
    );
});

export default SavedJobsSidebarView