import { Typography } from '@mui/material';
import moment from 'moment';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import MobileAppLogo from '../../assets/icons/mobile_app_logo';
import AppLogoRoundedSmall from '../../assets/images/app_logo_rounded small';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setFocusedJob } from '../../redux/reducers/appReducer';
import { RootState } from '../../redux/store';
import suggestedPromptsService from '../../services/suggestedPromptsService';
import UserAvatar from '../../shared/components/UserAvatar';
import APIJob, { combineJobData } from '../../shared/models/APIJob';
import Job from '../../shared/models/Job';
import Message, { MessageType } from '../../shared/models/Message';
import Resource from '../../shared/models/Resource';
import { AppColors } from '../../theme/AppTheme';
import { getCompanyName, getJobPromptNumber, getJobTitle, getPromptNumber } from '../utils/PromptExtractionUtils';
import { cleanStartMessage } from '../utils/ResumeHelper';
import ChatCell from './ChatCell';
import DesktopJobCard from './DesktopJobCard';
import MobileJobCard from './MobileJobCard';
import SuggestionsContent from './SuggestionsContent';
import { cleanUrl } from '../../shared/utils/StringUtils';

interface MessageCellProps {
    message: Message;
    jobsData?: APIJob[];
    suggestions?: string[];
    reference?: React.RefObject<HTMLDivElement>;
    onBookmarkPressed: (adding: boolean, resource: Resource) => void;
    onBookmarkJobPressed: () => void;
    isNewest: boolean;
    accessory?: ReactNode;
    documentName?: string;
    debug?: boolean;
    richContent?: boolean;
    hideDoc?: boolean;
    streaming?: boolean;
}

const getPrompt = (str: string, state: RootState): string | null => {
    const promptNum = getPromptNumber(str);
    if (promptNum) {
        return suggestedPromptsService.getFancySuggestedPrompts(state)[promptNum - 1].description;
    }
    const jobPromptNum = getJobPromptNumber(str);
    if (jobPromptNum) {
        const companyName = getCompanyName(str);
        const jobTitle = getJobTitle(str);
        return `${suggestedPromptsService.getJobPrompts(state.promptState.backendPrompts!)[jobPromptNum - 1].description} ${companyName ? `at ${companyName}` : ''} ${jobTitle ? `as a ${jobTitle}` : ''}`;
    }
    return null
}

const MessageCell = (props: MessageCellProps) => {
    const { message, jobsData, richContent, hideDoc, suggestions, reference, isNewest, accessory, documentName, debug, streaming, onBookmarkPressed, onBookmarkJobPressed } = props;
    const user = useAppSelector((s) => s.userState.currentUser);
    const viewedJobs = useAppSelector((s) => s.viewedJobsState.viewedJobs);
    const [urls, setUrls] = useState<string[]>([]);
    const [jobCardMode, setJobCardMode] = useState(jobsData ? true : false);
    const [resumeMode, setResumeMode] = useState(false);
    const [coverLetterMode, setCoverLetterMode] = useState(false);
    const isSent = message.type === MessageType.sent;
    const isMobile = useAppSelector((s) => s.appState.isMobile);
    const state = useAppSelector((s) => s);
    const dispatch = useAppDispatch();
    const [contactInfoFound, setContactInfoFound] = useState(false);
    let foundJobWords = { 'Job Title': false, 'Location': false, 'Skills': false, 'Fit Score': false, 'Company': false };
    let foundResumeHeaders = { 'Experience': false, 'Skills': false, 'Education': false, 'Summary': false, 'Objective': false, };

    useEffect(() => {
        if (jobCardMode || resumeMode) return;
        const extractedUrls = message.content?.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])(?![.,;])/gi);
        const uniqueUrls = extractedUrls ? Array.from(new Set(extractedUrls)) : [];
        setUrls(uniqueUrls);
        if (message.type !== MessageType.sent && message.content.includes('[RESUME]')) {
            setResumeMode(true);
        }
    }, [message]);

    const checkResumeHeaders = (str: string) => {
        const lowerCaseString = str.toLowerCase();
        const headers = ['Experience', 'Skills', 'Education', 'Summary', 'Objective', 'Publications', 'References', 'Projects', 'Research'];
        headers.forEach(header => {
            if (lowerCaseString.includes(header.toLowerCase())) {
                foundResumeHeaders = { ...foundResumeHeaders, [header]: true };
            }
            if (lowerCaseString.includes('resume') && !resumeMode) {
                setResumeMode(true);
            }
        });
        const headerCount = Object.values(foundResumeHeaders).filter(Boolean).length;
        if (headerCount >= 2 && contactInfoFound) {
            setResumeMode(true);
        }
    }

    const messageContainsEmailOrPhone = (message: string): boolean => {
        const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
        const phoneRegex = /(\d{3}[-\.\s]??\d{3}[-\.\s]??\d{4}|\(\d{3}\)\s*\d{3}[-\.\s]??\d{4}|\d{3}[-\.\s]??\d{4})/;
        const email = emailRegex.test(message)
        const phone = phoneRegex.test(message);
        return email || phone;
    }

    const messageContainsLetterGreetings = (message: string): boolean => {
        const dearRegex = /dear\s[a-zA-Z\s\[\]']+,?\n/i;
        const hiRegex = /hi\s[a-zA-Z\s\[\]']+,?\n/i;
        const helloRegex = /hello\s[a-zA-Z\s\[\]']+,?\n/i;
        const greetings = [dearRegex, hiRegex, helloRegex];
        return greetings.some(greeting => greeting.test(message));
    }

    const getFormattedMessage = (): string => {
        if (message.content.startsWith('[RESUME_') || message.content.startsWith('[COVER_LETTER_') || message.content.startsWith('[JOB_MATCH_') || message.content.startsWith('[QUESTIONS_') || message.content.startsWith('[UPSKILL_')) {
            return cleanStartMessage(message.content);
        }
        if (message.content.startsWith('[PROMPT') || message.content.startsWith('[JOB')) {
            return (getPrompt(message.content, state) ?? message.content?.replace(/\n/g, '  \n'))
        }
        if (message.content.startsWith('[RESUME]')) {
            return message.content.replace('[RESUME]', '').replace('[/RESUME]', '');
        }
        return message.content?.replace(/\n/g, '  \n');
    }

    const getResumeText = (): string | undefined => {
        const resumeRegex = /\[RESUME\]([\s\S]*?)\[\/RESUME\]/g;
        const match = resumeRegex.exec(message.content);
        return match ? match[1] : undefined;
    }

    if (!contactInfoFound) {
        const found = messageContainsEmailOrPhone(message.content);
        if (found) {
            setContactInfoFound(true);
        }
    }

    if (!resumeMode && !coverLetterMode) {
        const found = messageContainsLetterGreetings(message.content);
        if (found) {
            setCoverLetterMode(true);
        }
    }
    return (
        <div ref={reference} style={{ width: '100%' }}>
            <ChatCell
                onBookmarkPressed={onBookmarkPressed}
                suggestions={(jobCardMode || resumeMode) ? undefined : suggestions}
                avatar={isSent ? <UserAvatar small={isMobile} imageUrl={user?.avatarUrl} /> : (isMobile ? <MobileAppLogo /> : <AppLogoRoundedSmall />)}
                isSent={isSent}
                time={moment(message.dt).fromNow()}
                urls={(jobCardMode || resumeMode) ? undefined : urls}
                richContentMode={richContent ?? jobCardMode}
                resumeMode={(jobCardMode || hideDoc) ? false : resumeMode}
                coverLetterMode={hideDoc ? false : coverLetterMode}
                isNewest={isNewest}
                messageText={getFormattedMessage()}
                resumeText={resumeMode ? getResumeText() : undefined}
                documentName={documentName}
                messageIndex={message.i}
                mem={message.mem}
                debug={debug}
                content={
                    <Typography
                        variant='h5'
                        sx={{
                            textAlign: 'left',
                            color: AppColors.black,
                            userSelect: 'text',
                            webkitUserSelect: 'text',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                        }}
                    >
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                                code: ({ node, ...props }) => {
                                    return (
                                        <code {...props} style={{ WebkitUserSelect: 'text', userSelect: 'text' }}>
                                            {props.children}
                                        </code>
                                    )
                                },
                                strong: ({ node, ...props }) => {
                                    if (props.children && typeof props.children === 'string') {
                                        checkResumeHeaders(props.children);
                                    }
                                    return (
                                        <strong {...props} style={{ WebkitUserSelect: 'text', userSelect: 'text' }}>
                                            {props.children}
                                        </strong>
                                    )
                                },
                                b: ({ node, ...props }) => {
                                    if (props.children && typeof props.children === 'string') {
                                        checkResumeHeaders(props.children);
                                    }
                                    return (
                                        <b {...props} style={{ WebkitUserSelect: 'text', userSelect: 'text' }}>
                                            {props.children}
                                        </b>
                                    )
                                },
                                h1: ({ node, ...props }) => {
                                    if (props.children && typeof props.children === 'string') {
                                        checkResumeHeaders(props.children);
                                    }
                                    return (
                                        <h1 {...props} style={{ WebkitUserSelect: 'text', userSelect: 'text' }}>
                                            {props.children}
                                        </h1>

                                    )
                                },
                                h2: ({ node, ...props }) => {
                                    if (props.children && typeof props.children === 'string') {
                                        checkResumeHeaders(props.children);
                                    }
                                    return (
                                        <h2 {...props} style={{ WebkitUserSelect: 'text', userSelect: 'text' }}>
                                            {props.children}
                                        </h2>
                                    )
                                },
                                h3: ({ node, ...props }) => {
                                    if (props.children && typeof props.children === 'string') {
                                        checkResumeHeaders(props.children);
                                    }
                                    return (
                                        <h3 {...props} style={{ WebkitUserSelect: 'text', userSelect: 'text' }}>
                                            {props.children}
                                        </h3>
                                    )
                                },
                                h4: ({ node, ...props }) => {
                                    if (props.children && typeof props.children === 'string') {
                                        checkResumeHeaders(props.children);
                                    }
                                    return (
                                        <h4 {...props} style={{ WebkitUserSelect: 'text', userSelect: 'text' }}>
                                            {props.children}
                                        </h4>
                                    )
                                },
                                h5: ({ node, ...props }) => {
                                    if (props.children && typeof props.children === 'string') {
                                        checkResumeHeaders(props.children);
                                    }
                                    return (
                                        <h5 {...props} style={{ WebkitUserSelect: 'text', userSelect: 'text' }}>
                                            {props.children}
                                        </h5>
                                    )
                                },
                                h6: ({ node, ...props }) => {
                                    if (props.children && typeof props.children === 'string') {
                                        checkResumeHeaders(props.children);
                                    }
                                    return (
                                        <h6 {...props} style={{ WebkitUserSelect: 'text', userSelect: 'text' }}>
                                            {props.children}
                                        </h6>
                                    )
                                },
                                a: ({ node, ...props }) => (
                                    <a {...props} target="_blank" rel="noopener noreferrer">
                                        {props.children}
                                    </a>
                                ),
                                p: ({ node, ...props }) => (
                                    <p {...props} style={{ WebkitUserSelect: 'text', userSelect: 'text' }}>
                                        {props.children}
                                    </p>
                                ),
                                li: ({ node, ...props }) => (
                                    <li {...props} style={{ margin: '8px 0px', WebkitUserSelect: 'text', userSelect: 'text' }}>
                                        {props.children}
                                    </li>
                                ),
                                table: ({ node, ...props }) => {
                                    if (jobCardMode) {
                                        let columnInidicies = { 'Job Title': 100, 'Location': 300, 'Skills': 400, 'Fit Score': 100, 'Company': 200, 'Link': 500, 'Job Text': 600, 'Summary': 600 }

                                        const childrenArray: ReactNode[] = React.Children.toArray(props.children);
                                        const thead = childrenArray.find(child => React.isValidElement(child) && child.type === 'thead') as ReactElement | undefined;
                                        const tbody = childrenArray.find(child => React.isValidElement(child) && child.type === 'tbody') as ReactElement | undefined;

                                        if (!thead || !tbody) return (<></>)

                                        React.Children.map(thead!.props.children, (row: ReactElement, rowIndex: number) => {
                                            React.Children.map(row.props.children, (cell: ReactElement, cellIndex: number) => {
                                                if (typeof cell.props.children === 'string') {
                                                    const cellValue = cell.props.children as string;
                                                    if (cellValue.includes('Skills')) {
                                                        columnInidicies = { ...columnInidicies, 'Skills': cellIndex };
                                                    }
                                                    if (Object.keys(columnInidicies).includes(cellValue)) {
                                                        columnInidicies = { ...columnInidicies, [cellValue]: cellIndex };
                                                    }
                                                } else {
                                                    React.Children.forEach(cell.props.children as ReactElement, (child: ReactElement, index: number) => {
                                                        if (typeof child === 'string') {
                                                            const cellValue = child as string;
                                                            if (cellValue.includes('Skills')) {
                                                                columnInidicies = { ...columnInidicies, 'Skills': cellIndex };
                                                            }
                                                            if (Object.keys(columnInidicies).includes(cellValue)) {
                                                                columnInidicies = { ...columnInidicies, [cellValue]: cellIndex };
                                                            }
                                                        } else {
                                                            React.Children.forEach(child!.props.children, (child: ReactElement, index: number) => {
                                                                if (typeof child === 'string') {
                                                                    const cellValue = child as string;
                                                                    if (cellValue.includes('Skills')) {
                                                                        columnInidicies = { ...columnInidicies, 'Skills': cellIndex };
                                                                    }
                                                                    if (Object.keys(columnInidicies).includes(cellValue)) {
                                                                        columnInidicies = { ...columnInidicies, [cellValue]: cellIndex };
                                                                    }
                                                                }
                                                            });
                                                        }
                                                    });

                                                }
                                            });
                                        });

                                        // Build the header row
                                        const HeaderCell = (props: { title: string, cellIndex: number }) => {
                                            const { title, cellIndex } = props;
                                            return (<div style={{ textAlign: 'left', whiteSpace: 'nowrap', minWidth: widthForIndex(cellIndex), maxWidth: widthForIndex(cellIndex), marginLeft: marginForIndex(cellIndex) }} key={cellIndex}>
                                                {title}
                                            </div>);
                                        }

                                        const HeaderRow = <div style={{ display: 'flex', paddingLeft: '30px', paddingRight: '30px', marginBottom: '20px', marginTop: '30px', alignItems: 'center', }}>
                                            <HeaderCell cellIndex={0} title='' />
                                            <HeaderCell cellIndex={1} title='Job title' />
                                            <HeaderCell cellIndex={2} title='Location' />
                                            {/* <HeaderCell cellIndex={3} title='Skills' /> */}
                                        </div>;

                                        if (!isMobile && (!tbody || !(tbody.props))) return (HeaderRow);
                                        let jobs: Job[] = [];
                                        // Build job objects
                                        React.Children.map(tbody!.props.children, (row: ReactElement, rowIndex: number) => {
                                            let job = {} as Job;
                                            if (!row) return null;
                                            React.Children.map(row.props.children, (cell: ReactElement, cellIndex: number) => {
                                                let gotLink = false;
                                                if (!cell || !cell.props) return (<></>);
                                                let content = cell.props.children;
                                                if (typeof content !== 'string' && !!(content?.props)) {
                                                    const cellProps = content.props;
                                                    job.url = cellProps['href'];
                                                    content = content.props.children as string;
                                                    gotLink = true;
                                                }
                                                if (cellIndex === columnInidicies['Fit Score']) {
                                                    if (typeof content === 'string') {
                                                        if (content.includes('%')) {
                                                            content = parseInt(content.replace('%', ''));
                                                        }
                                                    }
                                                    job.fit = content as number;
                                                } else if (cellIndex === columnInidicies['Job Title']) {
                                                    job.title = content;
                                                } else if (cellIndex === columnInidicies['Company']) {
                                                    job.company = content;
                                                } else if (cellIndex === columnInidicies['Location']) {
                                                    if (typeof content === 'string') {
                                                        const parts = content.split(',');
                                                        if (parts.length > 1) {
                                                            job.location1 = parts[0];
                                                            job.location2 = parts[1].trim();
                                                        } else if (parts.length === 1) {
                                                            job.location1 = parts[0];
                                                        } else {
                                                            job.location1 = "Not specified"
                                                        }
                                                    }
                                                } else if (!job.skills && cellIndex === columnInidicies['Skills']) {
                                                    if (typeof content === 'string') {
                                                        job.skills = content;
                                                    }
                                                } else if (!job.url && cellIndex === columnInidicies['Link']) {
                                                    if (typeof content === 'string') {
                                                        job.url = content;
                                                    } else if (!!(content?.props)) {
                                                        const cellProps = content.props;
                                                        job.url = cellProps['href'];
                                                    }
                                                } else if (cellIndex === columnInidicies['Job Text'] || cellIndex === columnInidicies['Summary']) {
                                                    if (typeof content === 'string') {
                                                        job.summary = content;
                                                    }
                                                }
                                            });
                                            jobs.push(job);
                                        });

                                        const jobDataMap = new Map<string, APIJob>();
                                        jobsData?.forEach((jobData) => {
                                            const cleanJobUrl = cleanUrl(jobData.url);
                                            jobDataMap.set(cleanJobUrl, jobData);
                                            console.log(jobData.url);
                                        });

                                        // Build the job cards
                                        const JobCards = jobs.map((job, index) => {
                                            const cleanJobUrl = job?.url?.includes('gurulink') ? job.url : cleanUrl(job.url);
                                            const jobData = jobsData?.find((j) => (j.id === job.jobId)) ?? jobDataMap.get(cleanJobUrl);
                                            const combinedJob: Job = jobData ? combineJobData(job, jobData) : job;
                                            return isMobile
                                                ? <MobileJobCard key={index} job={job} jobData={jobData} index={index} viewed={!!viewedJobs[combinedJob.jobId ?? '']} onBookmarked={onBookmarkJobPressed} onClick={() => dispatch(setFocusedJob(combinedJob))} />
                                                : <DesktopJobCard key={index} job={job} index={index} jobData={jobData} viewed={!!viewedJobs[combinedJob.jobId ?? '']} onBookmarked={onBookmarkJobPressed} onClick={() => dispatch(setFocusedJob(combinedJob))} />
                                        });

                                        return (
                                            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                                {!isMobile ? HeaderRow : <div style={{ minHeight: '25px' }} />}
                                                {JobCards}
                                            </div>
                                        );

                                    }
                                    return (
                                        <div style={{ overflowX: 'auto', width: '100%', maxWidth: '100%' }}>
                                            <table {...props} style={{
                                                WebkitUserSelect: 'text', userSelect: 'text', minWidth: '100%', borderCollapse: 'separate',
                                                borderSpacing: '0 0',
                                                tableLayout: 'fixed'
                                            }}>
                                                {props.children}
                                            </table>
                                        </div>
                                    )
                                },
                                td: ({ node, ...props }) => {
                                    return (<td {...props} style={{
                                        WebkitUserSelect: 'text',
                                        userSelect: 'text',
                                        padding: '8px 16px 8px 8px',
                                        whiteSpace: 'normal',
                                        wordWrap: 'break-word'
                                    }}>
                                        {props.children}
                                    </td>
                                    )
                                },
                                th: ({ node, ...props }) => {
                                    const validValues = ['Job Title', 'Location', 'Skills', 'Key Skills', 'Fit Score', 'Company', 'Job Text', 'Summary'];
                                    if (typeof props.children === 'string') {
                                        if (props.children as string === 'Skills' || (props.children as string).includes('Skills')) {
                                            foundJobWords = { ...foundJobWords, 'Skills': true };
                                        }
                                    } else {
                                        React.Children.forEach(props.children as ReactElement, (child: ReactElement, index: number) => {
                                            if (typeof child === 'string') {
                                                if (child === 'Skills' || (child as string).includes('Skills')) {
                                                    foundJobWords = { ...foundJobWords, 'Skills': true };
                                                }
                                                if (validValues.includes(child as string)) {
                                                    foundJobWords = { ...foundJobWords, [child as string]: true };
                                                }
                                            } else {
                                                React.Children.forEach(child!.props.children, (child: ReactElement, index: number) => {
                                                    if (typeof child === 'string') {
                                                        if (child === 'Skills' || (child as string).includes('Skills')) {
                                                            foundJobWords = { ...foundJobWords, 'Skills': true };
                                                        }
                                                        if (validValues.includes(child as string)) {
                                                            foundJobWords = { ...foundJobWords, [child as string]: true };
                                                        }
                                                    }
                                                });
                                            }
                                        });
                                    }
                                    if (validValues.includes(props.children as string)) {
                                        foundJobWords = { ...foundJobWords, [props.children as string]: true };
                                    }
                                    const keywordCount = Object.values(foundJobWords).filter(Boolean).length;
                                    if (keywordCount >= 2 && !jobCardMode) {
                                        setJobCardMode(true);
                                    }
                                    return (
                                        <th {...props} style={{
                                            WebkitUserSelect: 'text',
                                            userSelect: 'text',
                                            padding: '8px 16px 8px 8px'
                                        }}>
                                            {props.children}
                                        </th>
                                    )
                                },
                            }}
                        >
                            {getFormattedMessage()}
                        </ReactMarkdown>
                        {(!streaming && jobCardMode && isNewest && (jobsData?.length ?? 0) > 0)
                            ? <SuggestionsContent inverted={true} title='Suggested Actions' suggestions={['Provide me with more job matches like these', /*'Provide me with a list of job matches that I haven\'t viewed before'*/]} />
                            : accessory}
                    </Typography >
                }
            />
        </div>
    );
}

export const marginForIndex = (index: number) => {
    switch (index) {
        case 0:
            return 0;
        case 1:
            return '3%';
        case 2:
            return '10%';
        case 3:
            return '7%';
        default:
            return '0';
    }
}

export const widthForIndex = (index: number, isMobile?: boolean) => {
    switch (index) {
        case 0:
            return isMobile ? '13%' : '10%';
        case 1:
            return '50%';
        case 2:
            return '15%';
        case 3:
            return '0%';
        default:
            return '0';
    }
}

export default MessageCell;

