import styled from "@emotion/styled";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setUser } from "../redux/reducers/userReducer";
import UserService from "../services/userService";
import JobProfileSection from "./components/JobProfileSection";
import ProfilePositionsSection from "./components/ProfilePositionsSection";
import ProfileSection from "./components/ProfileSection";
import ProfileSkillsSection from "./components/ProfileSkillsSection";
import PersonalInfo, { userInfoFromPersonalInfo } from "./models/PersonalInfo";


const ScrollingWrapper = styled('div')(() => ({
    overflowY: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
}));

const StyledContainer = styled('div')((props: { isMobile: boolean }) => ({
    padding: props.isMobile ? '24px' : '50px',
    maxWidth: '1200px',
    width: props.isMobile ? 'calc(100% - 48px)' : '100%',
}));

const ProfilePage = () => {
    const user = useAppSelector(store => store.userState.currentUser);
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const dispatch = useAppDispatch();

    const personalInfo: PersonalInfo = {
        'First Name': { value: user?.firstName ?? '' },
        'Last Name': { value: user?.lastName ?? '' },
        'Address': { value: user?.address ?? '' },
        'Phone': { value: user?.phone ?? '' },
        'Email': { value: user?.email ?? '' },
        'LinkedIn': { value: user?.linkedInUrl ?? '' },
    }

    const onProfileInfoUpdated = async (newData: PersonalInfo) => {
        try {
            const userInfo = userInfoFromPersonalInfo(newData);
            const updatedUser = await UserService.updateUser(userInfo);
            dispatch(setUser(updatedUser));
        } catch (e) {
            console.error(`Error updating user: ${e}`);
        }
    }

    return (
        <ScrollingWrapper>
            <StyledContainer isMobile={isMobile}>
                <ProfileSection title='Contact Information' data={personalInfo} onDataEdited={onProfileInfoUpdated} />
                <ProfileSkillsSection />
                <ProfilePositionsSection />
                <JobProfileSection />
                <div style={{ minHeight: '20px', maxHeight: '20px', }} />
            </StyledContainer>
        </ScrollingWrapper >
    )
}

export default ProfilePage