import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bars } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { Strings } from "../../i18n";
import { useAppSelector } from "../../redux/hooks";
import { setUser } from "../../redux/reducers/userReducer";
import skillsService from "../../services/skillsService";
import UserService from "../../services/userService";
import ClickableOpacityDiv from "../../shared/components/ClickableOpacityDiv";
import CloseButton from "../../shared/components/CloseButton";
import OutlinedButton from "../../shared/components/OutlinedButton";
import PPTextInput from "../../shared/components/PPTextInput";
import PrimaryButton from "../../shared/components/PrimaryButton";
import { isIOS } from "../../shared/utils/PlatformUtils";
import { AppColors } from "../../theme/AppTheme";
import SkillsArray from "./SkillsArray";
import SkillsSearch from "./SkillsSearch";
import { DefaultModel } from "../../shared/models/LLMModels";
import { on } from "events";

const StyledContainer = styled('div')((props: { isMobile: boolean }) => ({
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: props.isMobile ? '100%' : '70%',
    maxWidth: props.isMobile ? '100%' : '650px',
    minHeight: props.isMobile ? '100%' : '70vh',
    maxHeight: props.isMobile ? '100%' : '70vh',
    backgroundColor: AppColors.white,
    padding: props.isMobile ? '0px 20px' : undefined,
}));

const StyledScrollingContainer = styled('div')((props: { isMobile: boolean }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: props.isMobile ? 'calc(100%)' : '100%',
    overflowY: 'auto',
    backgroundColor: AppColors.white,
    padding: props.isMobile ? '0px 24px' : '0px 80px 64px',
    paddingBottom: '64px',
    flexGrow: 1,
}));

const StyledButtons = styled('div')((props: { isMobile: boolean }) => ({
    padding: '12px 75px',
    borderTop: `1px solid ${AppColors.grey.border}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '22px',
    width: props.isMobile ? 'calc(100%)' : '100%',
}));

interface SkillsScreenProps {
    hideCloseButton?: boolean;
    onClose: () => void;
    onNext?: () => void;
    onboarding?: boolean;
}

const SkillsScreen = (props: SkillsScreenProps) => {
    const { onboarding, hideCloseButton, onClose } = props;
    const { t } = useTranslation();
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const user = useAppSelector(store => store.userState.currentUser);

    const [showSearch, setShowSearch] = useState(false);
    const [skills, setSkills] = useState<string[]>([]);
    const [recommendedSkills, setRecommendedSkills] = useState<string[]>([]);
    const [recommendedSkillsLoading, setRecommendedSkillsLoading] = useState(true);
    const [topBarFull, setTopBarFull] = useState(true);

    // Skill Search
    const [searchText, setSearchText] = useState('');
    const [searchFillColor, setSearchFillColor] = useState(AppColors.grey.lightest);
    const [searchPlaceholderColor, setSearchPlaceholderColor] = useState(AppColors.grey.dark);
    const [searchBorderColor, setSearchBorderColor] = useState(AppColors.white);

    // Clear
    const [cleared, setCleared] = useState(false);
    const [clearedBuffer, setClearedBuffer] = useState<string[]>([]);

    const [saving, setSaving] = useState(false);

    const anchorElement = useRef<HTMLDivElement>(null);
    const scrollContainer = useRef<HTMLDivElement>(null);
    const innerScrollContainer = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        setSkills(user?.info?.skills ?? []);
    }, [user, user?.info, user?.info?.skills]);

    useEffect(() => {
        const fetchRecommendedSkills = async () => {
            try {
                const recos = await skillsService.getRecommendedSkillsAndPositions(DefaultModel);
                setRecommendedSkills(recos?.skills ?? []);
            } catch (e) {
                console.error(e);
            } finally {
                setRecommendedSkillsLoading(false);
            }
        }
        fetchRecommendedSkills();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const isIos = isIOS();
            if (innerScrollContainer.current) {
                const scrollTop = isIos
                    ? -(document.getElementById('innerContainer')?.getBoundingClientRect().top ?? 0)
                    : innerScrollContainer.current.scrollTop;
                // truncate the decimal
                const roundedScrollTop = Math.round(scrollTop);
                if (!topBarFull && roundedScrollTop < 45) {
                    setTopBarFull(true);
                } else if (topBarFull && roundedScrollTop >= 53) {
                    setTopBarFull(false);
                }
            }
        };

        if (innerScrollContainer.current) {
            innerScrollContainer.current.addEventListener('scroll', handleScroll);
            innerScrollContainer.current.addEventListener('touchmove', handleScroll);
        }

        return () => {
            if (innerScrollContainer.current) {
                innerScrollContainer.current.removeEventListener('scroll', handleScroll);
                innerScrollContainer.current.removeEventListener('touchmove', handleScroll);
            }
        };
    }, [innerScrollContainer, topBarFull]);

    const onSave = async () => {
        setSaving(true);
        try {
            const user = await UserService.updateUserInfo({ skills });
            dispatch(setUser(user));
            if (onboarding) {
                props.onNext?.();
            } else {
                onClose()
            }
        } catch (e) {
            console.error(e);
        } finally {
            setSaving(false);
        }
    }

    const onSkillAdded = (skill: string) => {
        setRecommendedSkills(recommendedSkills.filter(s => s !== skill));
        setSkills([...skills, skill]);
        if (cleared) {
            setCleared(false);
        }
    }

    const onSkillRemoved = (skill: string) => {
        setSkills(skills.filter(s => s !== skill));
    }

    const onSearchFocused = () => {
        setSearchFillColor(AppColors.white);
        setSearchPlaceholderColor(AppColors.grey.darkish);
        setSearchBorderColor(AppColors.grey.border);
        setShowSearch(true);

    }

    const onSearchBlurred = () => {
        setSearchFillColor(AppColors.grey.lightest);
        setSearchPlaceholderColor(AppColors.grey.dark);
        setSearchBorderColor(AppColors.white);
        setShowSearch(false);
    }

    const onSearchCompleted = () => {
        setSearchText('');
        setShowSearch(false);
        onSearchBlurred();
    }

    const clearAllSelected = () => {
        setClearedBuffer(skills);
        setSkills([]);
        setCleared(true);
    }

    const undoClear = () => {
        setSkills(clearedBuffer);
        setCleared(false);
    }

    const suggestionSubtitle = user?.resume?.textResume ? 'Below are skills and keywords suggested based on your resume.' : 'Upload your resume to see suggested skills.';

    return (
        <StyledContainer isMobile={isMobile} ref={scrollContainer}>
            <CloseButton onClick={onClose} back={onboarding} />
            <StyledScrollingContainer ref={innerScrollContainer} isMobile={isMobile}>
                {showSearch &&
                    <SkillsSearch
                        searchText={searchText}
                        onSelectSkill={onSkillAdded}
                        onRemoveSkill={onSkillRemoved}
                        selectedSkills={skills}
                        anchorElement={anchorElement.current}
                        scrollContainer={innerScrollContainer.current}
                        onDone={onSearchCompleted}
                    />}
                <span style={{ minHeight: '54px' }} />
                <Typography variant='h2' style={{ transition: 'all 0.2s ease-in-out', fontSize: topBarFull ? '21px' : '16px', zIndex: 9999999, marginBottom: '9px', position: 'sticky', top: 0, width: '100%', textAlign: 'center', padding: "12px 0px", background: "white" }}>
                    {`${onboarding ? 'Add' : 'Edit'} Professional Skills`}
                </Typography>
                <Typography variant='h5' style={{
                    textAlign: 'center',
                    marginBottom: '40px',
                    color: AppColors.grey.darkish
                }}>
                    Select 3 or more skills below.<br />These skills will be used to help match you to jobs.
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                    <Typography variant="body2" style={{ width: '100%', textAlign: 'left', marginBottom: '16px' }}>
                        Your Skills
                    </Typography>
                    {cleared ? <div style={{
                        display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', alignItems: 'center',
                    }}>
                        <Typography variant="body1" style={{ fontWeight: '400', textAlign: 'right', color: AppColors.grey.dark, marginBottom: '16px', marginRight: '4px' }}>
                            Skills cleared
                        </Typography>
                        <Typography variant="body1" style={{ textAlign: 'right', color: AppColors.grey.dark, marginBottom: '16px', }}>
                            (
                        </Typography>
                        <ClickableOpacityDiv onClick={undoClear} style={{ textAlign: 'right', marginBottom: '16px', padding: '0', color: AppColors.blue }}>
                            <Typography variant="body1" style={{ width: '100%', textAlign: 'right', color: AppColors.blue }}>
                                undo
                            </Typography>
                        </ClickableOpacityDiv>
                        <Typography variant="body1" style={{ textAlign: 'right', color: AppColors.grey.dark, marginBottom: '16px', }}>
                            )
                        </Typography>
                    </div> :
                        <ClickableOpacityDiv onClick={clearAllSelected} style={{ textAlign: 'right', width: '100px', marginBottom: '16px', padding: '0', color: AppColors.blue }}>
                            <Typography variant="body1" style={{ width: '100%', textAlign: 'right', color: AppColors.blue }}>
                                Clear All
                            </Typography>
                        </ClickableOpacityDiv>}
                </div>
                <PPTextInput id={'skill-search'} reference={anchorElement}
                    value={searchText}
                    onValueChanged={(newValue) => setSearchText(newValue)}
                    bottomPadding="28px"
                    placeholder="Enter skills and keywords"
                    placeholderColor={searchPlaceholderColor}
                    fillColor={searchFillColor}
                    borderColor={searchBorderColor}
                    onFocused={onSearchFocused}
                    // onBlurred={onSearchBlurred}
                    zIndex={1000}
                />
                <SkillsArray skills={skills} onRemove={onSkillRemoved} allSelected={true} emptyStateText="" />
                <span style={{ minHeight: '40px' }} />
                <SkillsArray title={'Suggested Skills'} subtitle={suggestionSubtitle} skills={recommendedSkills} onSelected={onSkillAdded} allSelected={false} loading={recommendedSkillsLoading} emptyStateText="" />
            </StyledScrollingContainer>
            <StyledButtons isMobile={isMobile}>
                <PrimaryButton title={onboarding ? 'NEXT' : 'SAVE'} height={'48px'} disabled={skills.length < 3} paddingHorizontal="20%" onClick={onSave} />
            </StyledButtons>
            {saving && <div style={{ zIndex: 999999, background: 'rgba(0,0,0,0.3)', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Bars
                    height="30"
                    width="30"
                    color={AppColors.pink.dark}
                    ariaLabel="bars-loading"
                    visible={true}
                />
            </div>}
        </StyledContainer>
    )
}

export default SkillsScreen