import { LoadScript } from '@react-google-maps/api';
import { User, onAuthStateChanged } from 'firebase/auth';
import { useEffect, useCallback } from 'react';
import './App.css';
import { auth } from './firebaseConfigs';
import DesktopNavigation from './navigation/DesktopNavigation';
import MobileNavigation from './navigation/MobileNavigation';
import { LogoutAction } from './redux/actions/GlobalActions';
import { SetCachedUserAction, VerifyEmailAction } from './redux/actions/UserActions';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { setMobile } from './redux/reducers/appReducer';
import { doneInitializing, setFirebaseUser } from './redux/reducers/userReducer';
import { getAuthCode } from './shared/utils/UrlUtils';
import posthog from 'posthog-js';
import messageListener from './shared/utils/RemoteMessageListener';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '';
const GOOGLE_MAPS_LIBRARIES: ("places")[] = ['places'];

function App() {
  const isMobile = useAppSelector(store => store.appState.isMobile);
  const dispatch = useAppDispatch();

  // Debounce resize handler to avoid excessive updates
  const handleWindowSizeChange = useCallback(() => {
    const shouldBeMobile = window.innerWidth <= 768;
    if (shouldBeMobile !== isMobile) {
      dispatch(setMobile(shouldBeMobile));
    }
  }, [isMobile, dispatch]);

  // Set up auth state listener properly in useEffect
  useEffect(() => {
    let currentUser: User | null = null;

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (currentUser?.uid === user.uid) {
          return;
        }
        posthog.identify(
          user.uid,
          { email: user.email }
        );
        messageListener.listenForMessages(dispatch, user.uid);
        currentUser = user;
        if (!user.emailVerified) {
          dispatch(VerifyEmailAction());
          dispatch(doneInitializing());
          dispatch(setFirebaseUser(user));
          return;
        }
        console.log('User logged in app style');
        dispatch(SetCachedUserAction());
      } else {
        messageListener.stopListeningForMessages();
        currentUser = null
        dispatch(LogoutAction());
        dispatch(doneInitializing());
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  // Setup initial state
  useEffect(() => {
    dispatch(setMobile(window.innerWidth <= 768));
    dispatch(setFirebaseUser(auth.currentUser));
    getAuthCode();
  }, [dispatch]);

  // Setup resize listener with proper cleanup
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);

  return (
    <LoadScript
      googleMapsApiKey={GOOGLE_MAPS_API_KEY}
      libraries={GOOGLE_MAPS_LIBRARIES}
    >
      <div className="App">
        {!isMobile && <DesktopNavigation />}
        {isMobile && <MobileNavigation />}
      </div>
    </LoadScript>
  );
}

export default App;