export enum UserType {
    jobSeeker = 'jobSeeker',
    careerProfessional = 'careerProfessional'
}

class UserTypeManager {
    static getUserType(): UserType | undefined {
        const storedType = localStorage.getItem('userCode');
        console.log('storedType:', storedType);
        if (!storedType) {
            return undefined;
        }
        return storedType as UserType;
    }

    static setTypeFromCode(userCode: string): boolean {
        if (userCode.toLowerCase() === 'cdp') {
            console.log('setting cdp');
            localStorage.setItem('userCode', UserType.careerProfessional);
        } else if (userCode.toLowerCase() === 'jobseeker') {
            console.log('setting jobseeker');
            localStorage.setItem('userCode', UserType.jobSeeker);
        } else {
            return false;
        }
        return true;
    }

    static clearUserType(): void {
        console.log('clearing user type');
        localStorage.removeItem('userCode');
    }

}

export default UserTypeManager;