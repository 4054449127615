import { FetchAndShowChatAction } from "../../redux/actions/ChatActions";
import { setAdvancedInterviewActive } from "../../redux/reducers/appReducer";
import RealTimeMessageHandler from "./RealTimeMessageHandler";

class RemoteMessageListener {
    listenForMessages = (dispatch: any, userId: string) => {
        RealTimeMessageHandler.listenForMessages(userId ?? '', (sessionId: string) => {
            dispatch(FetchAndShowChatAction(sessionId, undefined, true));
        });
    }

    stopListeningForMessages = () => {
        RealTimeMessageHandler.stopListeningForMessages();
    }
}

const messageListener = new RemoteMessageListener();
export default messageListener;