import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import { MainTab } from '../../navigation/classes/MainTab';
import preambleService from '../../services/preambleService';
import Chat from '../../shared/models/Chat';
import ChatSession from '../../shared/models/ChatSession';
import Job from '../../shared/models/Job';
import { DefaultModel, initialModelForKey, modelFromString, modelToString } from '../../shared/models/LLMModels';
import { ModelWithType } from '../../services/modelsService';

interface AppState {
    selectedTab: number;
    isMobile: boolean;
    drawerIsOpen: boolean;
    sidebarIsOpen: boolean;
    sidebar?: ReactNode;
    activeChat?: Chat;
    chatHistorySearchTerm?: string;
    jobSearchTerm?: string;
    chatHistoryArray: ChatSession[];
    chatHistory: { [key: string]: ChatSession[] };
    chatHistoryBuckets: string[];
    filteredChatHistory: { [key: string]: ChatSession[] };
    fetchingChat: boolean;
    selectedProfileTab?: number;
    abortController?: AbortController;

    searchJobToggle: boolean;
    focusedJob?: Job;

    focusedTextToggle: boolean;
    currentChatAction?: string;

    chatModel: ModelWithType;
    suggModel: ModelWithType;
    jobSummaryModel: ModelWithType;
    jobSummaryPreamble: string;
    jobSummaryMessage: string;
    useSuggestedPrompts: boolean;

    followChatToggle: boolean;

    debugMode: boolean;
    memory: boolean;

    loadUniqueJobs: boolean;
    voiceInterviewActive: boolean;
    advancedInterviewActive: boolean;
}

const initialState: AppState = {
    selectedTab: -1,
    isMobile: false,
    drawerIsOpen: window.innerWidth > 768,
    sidebarIsOpen: false,
    chatHistory: {},
    chatHistoryBuckets: [],
    chatHistoryArray: [],
    filteredChatHistory: {},
    fetchingChat: false,
    focusedTextToggle: false,
    searchJobToggle: false,
    followChatToggle: false,
    debugMode: false,
    loadUniqueJobs: false,
    voiceInterviewActive: false,
    advancedInterviewActive: false,
    memory: localStorage.getItem('useMemory') ? localStorage.getItem('useMemory') === 'true' : false,
    chatModel: initialModelForKey('chatsModel') ?? DefaultModel,
    suggModel: initialModelForKey('suggestModel') ?? DefaultModel,
    jobSummaryModel: initialModelForKey('jobSumryModel') ?? DefaultModel,
    jobSummaryPreamble: localStorage.getItem('jobSumPre') ?? preambleService.getJobSummaryPreamble(),
    jobSummaryMessage: localStorage.getItem('jobSumMsg2') ?? preambleService.getJobSummaryMessage(),
    useSuggestedPrompts: localStorage.getItem('useSuggestedPrompts') ? localStorage.getItem('useSuggestedPrompts') === 'true' : true,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setSelectedTab: (state, action: PayloadAction<number>) => {
            state.selectedTab = action.payload;
            const sidebar = new MainTab(state.selectedTab).sidebar;
            const hasSidebar = !!sidebar;
            state.sidebarIsOpen = hasSidebar;
            if (state.isMobile) {
                state.sidebar = sidebar;
            } else if (sidebar) {
                state.sidebar = sidebar;
            }
        },
        setMobile: (state, action: PayloadAction<boolean>) => {
            state.isMobile = action.payload;
        },
        setDrawerIsOpen: (state, action: PayloadAction<boolean>) => {
            state.drawerIsOpen = action.payload;
        },
        setSidebarIsOpen: (state, action: PayloadAction<boolean>) => {
            state.sidebarIsOpen = action.payload;
        },
        setActiveChat: (state, action: PayloadAction<Chat>) => {
            state.selectedProfileTab = undefined;
            state.activeChat = action.payload;
        },
        clearActiveChat: (state) => {
            state.activeChat = undefined;
            state.selectedProfileTab = undefined;
            state.focusedTextToggle = !state.focusedTextToggle;
            state.focusedJob = undefined;
            state.currentChatAction = undefined;
        },
        updateChatSession: (state, action: PayloadAction<ChatSession>) => {
            Object.keys(state.chatHistory).forEach((bucket) => {
                state.chatHistory[bucket] = state.chatHistory[bucket].map((cs) =>
                    cs.sessionId === action.payload.sessionId ? action.payload : cs
                );
            });
            const filterIsActive = !!state.chatHistorySearchTerm && state.chatHistorySearchTerm.trim() !== '';
            if (!filterIsActive || action.payload.title.toLowerCase().includes(state.chatHistorySearchTerm!)) {
                Object.keys(state.filteredChatHistory).forEach((bucket) => {
                    state.filteredChatHistory[bucket] = state.filteredChatHistory[bucket].map((cs) => {
                        return cs.sessionId === action.payload.sessionId ? action.payload : cs;
                    }
                    );
                });
            }
        },
        addChatToHistory: (state, action: PayloadAction<ChatSession>) => {
            if (state.chatHistory['Today'] === undefined) {
                state.chatHistory['Today'] = [];
                state.filteredChatHistory['Today'] = [];
            }
            state.chatHistory['Today'].unshift(action.payload);
            const filterIsActive = !!state.chatHistorySearchTerm && state.chatHistorySearchTerm.trim() !== '';
            if (!filterIsActive || action.payload.title.toLowerCase().includes(state.chatHistorySearchTerm!)) {
                state.filteredChatHistory['Today'].unshift(action.payload);
            }
        },
        removeChatFromHistory: (state, action: PayloadAction<ChatSession>) => {
            Object.keys(state.chatHistory).forEach((bucket) => {
                state.chatHistory[bucket] = state.chatHistory[bucket].filter((cs) =>
                    cs.sessionId !== action.payload.sessionId
                );
                if (state.chatHistory[bucket]?.length === 0) {
                    delete state.chatHistory[bucket];
                }
            });
            Object.keys(state.filteredChatHistory).forEach((bucket) => {
                state.filteredChatHistory[bucket] = state.filteredChatHistory[bucket].filter((cs) =>
                    cs.sessionId !== action.payload.sessionId
                );
                if (state.filteredChatHistory[bucket]?.length === 0) {
                    delete state.filteredChatHistory[bucket];
                }
            });
        },
        clearChatHistory: (state) => {
            state.chatHistorySearchTerm = undefined;
            state.chatHistory = {};
            state.filteredChatHistory = {};
        },
        clearChatHistoryBuckets: (state) => {
            state.chatHistoryBuckets = [];
        },
        setChatHistoryBuckets: (state, action: PayloadAction<string[]>) => {
            state.chatHistoryBuckets = action.payload;
        },
        setChatHistory: (state, action: PayloadAction<{ [key: string]: ChatSession[] }>) => {
            state.chatHistorySearchTerm = undefined;
            state.chatHistory = action.payload;
            state.filteredChatHistory = action.payload;
        },
        setChatHistoryArray: (state, action: PayloadAction<ChatSession[]>) => {
            state.chatHistoryArray = action.payload;
        },
        setFetchingChat: (state, action: PayloadAction<boolean>) => {
            state.fetchingChat = action.payload;
            state.selectedProfileTab = undefined;
        },
        setUserStoppedStreaming: (state, action: PayloadAction<boolean>) => {
            const chatId = state.activeChat?.sessionId;
            if (chatId === undefined) {
                state.activeChat = {
                    ...state.activeChat!,
                    stoppedByUser: true,
                    streaming: false,
                }
            } else if (chatId === state.activeChat?.sessionId) {
                if (action.payload) {
                    state.activeChat = {
                        ...state.activeChat!,
                        stoppedByUser: true,
                        streaming: false,
                    }
                } else {
                    state.activeChat = {
                        ...state.activeChat!,
                        stoppedByUser: false,
                        streaming: false,
                    }
                }
            } else {
                // TODO: Let the user changes chats while generating in the future - maybe? ask Drew
            }
        },
        setSelectedProfileTab: (state, action: PayloadAction<number | undefined>) => {
            state.selectedProfileTab = action.payload;
        },
        setAbortController: (state, action: PayloadAction<AbortController>) => {
            state.abortController = action.payload;
        },
        setChatHistorySearchText: (state, action: PayloadAction<string>) => {
            const searchTerm = action.payload.toLowerCase();
            if (searchTerm.trim() === '') {
                state.chatHistorySearchTerm = undefined;
                state.filteredChatHistory = state.chatHistory;
                return;
            }
            state.chatHistorySearchTerm = searchTerm;
            state.filteredChatHistory = {};
            Object.keys(state.chatHistory).forEach((bucket) => {
                state.filteredChatHistory[bucket] = state.chatHistory[bucket].filter((cs) => cs.title.toLowerCase().includes(searchTerm));
            });
        },
        setJobSearchText: (state, action: PayloadAction<string>) => {
            state.jobSearchTerm = action.payload;
            state.searchJobToggle = !state.searchJobToggle;
        },
        setFocusedJob: (state, action: PayloadAction<Job>) => {
            state.selectedProfileTab = undefined;
            state.focusedJob = action.payload;
        },
        clearFocusedJob: (state) => {
            state.focusedJob = undefined;
        },
        focusText: (state) => {
            state.focusedTextToggle = !state.focusedTextToggle;
        },
        setChatModel: (state, action: PayloadAction<ModelWithType>) => {
            state.chatModel = action.payload;
            localStorage.setItem('chatsModel', modelToString(action.payload));
        },
        setSuggModel: (state, action: PayloadAction<ModelWithType>) => {
            state.suggModel = action.payload;
            localStorage.setItem('suggestModel', modelToString(action.payload));
        },
        setJobSummaryModel: (state, action: PayloadAction<ModelWithType>) => {
            state.jobSummaryModel = action.payload;
            localStorage.setItem('jobSumryModel', modelToString(action.payload));
        },
        setJobSummaryPreamble: (state, action: PayloadAction<string>) => {
            state.jobSummaryPreamble = action.payload;
            localStorage.setItem('jobSummaryPreamble', action.payload);
        },
        resetJobSummaryPreamble: (state) => {
            state.jobSummaryPreamble = preambleService.getJobSummaryPreamble();
            localStorage.setItem('jobSummaryPreamble', preambleService.getJobSummaryPreamble());
        },
        setJobSummaryMessage: (state, action: PayloadAction<string>) => {
            state.jobSummaryMessage = action.payload;
            localStorage.setItem('jobSumMsg2', action.payload);
        },
        resetJobSummaryMessage: (state) => {
            state.jobSummaryMessage = preambleService.getJobSummaryMessage();
            localStorage.setItem('jobSumMsg2', preambleService.getJobSummaryMessage());
        },
        setUseSuggestedPrompts: (state, action: PayloadAction<boolean>) => {
            state.useSuggestedPrompts = action.payload;
            localStorage.setItem('useSuggestedPrompts', action.payload.toString());
        },
        setCurrentChatAction: (state, action: PayloadAction<string | undefined>) => {
            state.currentChatAction = action.payload;
        },
        toggleFollowChat: (state) => {
            state.followChatToggle = !state.followChatToggle;
        },
        setDebugMode: (state, action: PayloadAction<boolean>) => {
            state.debugMode = action.payload;
        },
        setMemory: (state, action: PayloadAction<boolean>) => {
            state.memory = action.payload;
            localStorage.setItem('useMemory', action.payload ? 'true' : 'false');
        },
        setLoadUniqueJobs: (state, action: PayloadAction<boolean>) => {
            state.loadUniqueJobs = action.payload;
        },
        setVoiceInterviewActive: (state, action: PayloadAction<boolean>) => {
            state.voiceInterviewActive = action.payload;
        },
        setAdvancedInterviewActive: (state, action: PayloadAction<boolean>) => {
            state.advancedInterviewActive = action.payload;
        }
    },
})

export const {
    setSelectedTab,
    setMobile,
    setDrawerIsOpen,
    setSidebarIsOpen,
    setActiveChat,
    clearActiveChat,
    addChatToHistory,
    removeChatFromHistory,
    clearChatHistory,
    setChatHistory,
    setChatHistoryArray,
    setChatHistorySearchText,
    setChatHistoryBuckets,
    clearChatHistoryBuckets,
    setJobSearchText,
    setFetchingChat,
    setUserStoppedStreaming,
    setSelectedProfileTab,
    setAbortController,
    setFocusedJob,
    clearFocusedJob,
    focusText,
    updateChatSession,
    setChatModel,
    setSuggModel,
    setJobSummaryModel,
    setJobSummaryPreamble,
    resetJobSummaryPreamble,
    setJobSummaryMessage,
    resetJobSummaryMessage,
    setUseSuggestedPrompts,
    setCurrentChatAction,
    toggleFollowChat,
    setDebugMode,
    setMemory,
    setLoadUniqueJobs,
    setVoiceInterviewActive,
    setAdvancedInterviewActive,
} = appSlice.actions

export default appSlice.reducer
