import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "../../firebaseConfigs"

class RealTimeMessageHandler {
    static unsubscribe: (() => void) | null = null;
    static isFirstSnapshot: boolean = true;

    static listenForMessages = (userId: string, messageReceived: (sessionId: string) => void) => {
        if (!!RealTimeMessageHandler.unsubscribe) {
            return;
        }

        RealTimeMessageHandler.isFirstSnapshot = true;

        const q = query(
            collection(firestore, "messages"),
            where("userId", "==", userId)
        );

        RealTimeMessageHandler.unsubscribe = onSnapshot(q, (snapshot) => {
            if (RealTimeMessageHandler.isFirstSnapshot) {
                RealTimeMessageHandler.isFirstSnapshot = false;
                console.log('Ignoring initial snapshot');
                return;
            }

            // Get all documents in the snapshot
            const documents: any[] = snapshot.docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                };
            });

            // Find the document with the newest createdAt timestamp
            if (documents.length > 0) {
                let newestDoc = documents[0];

                for (const doc of documents) {
                    // Compare timestamps (assuming createdAt is a Firebase timestamp or something that can be compared)
                    if (doc.createdAt && newestDoc.createdAt &&
                        doc.createdAt > newestDoc.createdAt) {
                        newestDoc = doc;
                    }
                }

                console.log('Newest document:', newestDoc.id, " => ", newestDoc);

                // Only send the sessionId from the newest document
                if (newestDoc.sessionId) {
                    messageReceived(newestDoc.sessionId);
                }
            }
        });
        console.log('Listening for messages', userId);
    }

    static stopListeningForMessages = () => {
        if (RealTimeMessageHandler.unsubscribe) {
            RealTimeMessageHandler.unsubscribe();
            RealTimeMessageHandler.unsubscribe = null;
            RealTimeMessageHandler.isFirstSnapshot = true;
        }
        console.log('Stopped listening for messages');
    }
}

export default RealTimeMessageHandler;