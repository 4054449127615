import { Typography } from "@mui/material";
import { useLayoutEffect, useRef, useState } from "react";
import CaretDownIcon from "../../assets/icons/caret_down_icon";
import PPTextInput from "../../shared/components/PPTextInput";
import SelectOption from "./SingleSelectOption";

interface SingleSelectOptionProps {
    title: string;
    placeholder: string;
    selectedValues: string[];
    possibleValues: string[];
    scrollContainer: HTMLElement | null;
    valueUpdated: (value: string[]) => void;
    multiSelect?: boolean;
}

const SingleSelectOptionInput = (props: SingleSelectOptionProps) => {
    const { title, placeholder, selectedValues, possibleValues, scrollContainer, multiSelect, valueUpdated } = props;

    const [showSearch, setShowSearch] = useState(false);
    const [value, setValue] = useState<string[]>(selectedValues);

    const inputRef = useRef<HTMLInputElement>(null);
    const buttonRef = useRef<HTMLInputElement>(null);

    useLayoutEffect(() => {
        // Put button on top of the input
        if (inputRef.current && buttonRef.current && scrollContainer) {
            const inputRect = inputRef.current.getBoundingClientRect();
            const scrollContainerRect = scrollContainer.getBoundingClientRect();
            buttonRef.current.style.position = 'absolute';
            buttonRef.current.style.top = `${inputRect.top + scrollContainer.scrollTop - scrollContainerRect.top}px`;
            buttonRef.current.style.left = `${inputRect.left - scrollContainerRect.left}px`;
            buttonRef.current.style.width = `${inputRect.width}px`;
            buttonRef.current.style.height = `${inputRect.height}px`;
            buttonRef.current.style.zIndex = '1';
        }
    }, [inputRef, buttonRef, scrollContainer]);

    const onValueSelected = (item: string) => {
        if (multiSelect) {
            const newValues = value.includes(item) ? value.filter(v => v !== item) : [...value, item];
            valueUpdated(newValues);
            setValue(newValues);
        } else {
            valueUpdated([item]);
            setValue([item]);
            onSearchCompleted();
        }
    }

    const onSearchFocused = () => {
        setShowSearch(true);
    }

    const onSearchCompleted = () => {
        setShowSearch(false);
    }

    return (
        <>
            {showSearch &&
                <SelectOption
                    scrollContainer={scrollContainer}
                    onSelected={onValueSelected}
                    selectedItems={value}
                    onDone={onSearchCompleted}
                    anchorElement={inputRef.current}
                    title={placeholder}
                    allItems={possibleValues}
                />}
            <Typography variant='h4' style={{ width: '100%', textAlign: 'left', paddingBottom: '16px' }}>
                {title}
            </Typography>
            {/* <div ref={buttonRef} style={{ cursor: 'pointer' }} onClick={onSearchFocused} /> */}
            <PPTextInput id={'single-search'}
                reference={inputRef}
                value={value.join(', ')}
                onValueChanged={() => { }}
                bottomPadding="30px"
                placeholder={placeholder}
                onFocused={() => {
                    onSearchFocused();
                    inputRef.current?.blur();
                }}
                trailingIcon={<div style={{ marginRight: '22px' }}><CaretDownIcon /></div>}
            />
        </>
    )
}

export default SingleSelectOptionInput