import styled from "@emotion/styled";
import { SetJobMatchTypeAction } from "../../redux/actions/JobMatchActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import SuggestionRow from "../../shared/components/SuggestionRow";
import JobMatchType, { textForJobMatchType } from "../../shared/enums/JobMatchType";
import { IOSSwitch } from "../../profile/AuthenticationPage";
import { useState } from "react";
import { Checkbox, ToggleButton, Typography } from "@mui/material";
import { setLoadUniqueJobs } from "../../redux/reducers/appReducer";
import { SetTopLevelJobMatchTypeAction } from "../../redux/actions/TopLevelJobMatchActions";
import TopLevelJobMatchType, { textForTopLevelJobMatchType } from "../../shared/enums/TopLevelJobMatchType";
import ClickableDiv from "../../shared/components/ClickableDiv";
import ClickableOpacityDiv from "../../shared/components/ClickableOpacityDiv";

const StyledRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    maxWidth: '100%',
    gap: '15px',
}));

const TopLevelJobMatchTypeSelection = () => {
    const dispatch = useAppDispatch();
    const showUnique = useAppSelector(store => store.appState.loadUniqueJobs);

    const onTypeOfTopLevelJobMatchSelected = (type: TopLevelJobMatchType) => {
        dispatch(SetTopLevelJobMatchTypeAction(type));
    }

    const onTypeOfJobMatchSelected = (type: JobMatchType) => {
        dispatch(SetJobMatchTypeAction(type, showUnique));
    }

    return (
        <StyledRow>
            <div style={{ width: '100%', marginTop: '16px', display: 'flex', flexDirection: 'column', paddingBottom: '10px' }}>
                How would you like to find jobs?
            </div>
            <SuggestionRow key={JobMatchType.resumeAndPreferences} title={textForJobMatchType(JobMatchType.resumeAndPreferences)} onClick={() => onTypeOfJobMatchSelected(JobMatchType.resumeAndPreferences)} />
            <SuggestionRow key={JobMatchType.specificRole} title={textForJobMatchType(JobMatchType.specificRole)} onClick={() => onTypeOfJobMatchSelected(JobMatchType.specificRole)} />
            <SuggestionRow key={TopLevelJobMatchType.advice} title={textForTopLevelJobMatchType(TopLevelJobMatchType.advice)} onClick={() => onTypeOfTopLevelJobMatchSelected(TopLevelJobMatchType.advice)} />

            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: '', marginTop: '12px' }}>
                <ClickableOpacityDiv onClick={() => dispatch(setLoadUniqueJobs(!showUnique))} style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '8px', alignItems: 'center' }}>
                    <Checkbox style={{ width: '24px', height: '24px' }} color={'secondary'} checked={showUnique} onChange={(event) => dispatch(setLoadUniqueJobs(event.target.checked))} />
                    <Typography variant='h5' style={{ fontWeight: 400, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>Only show jobs I haven't viewed before</Typography>
                </ClickableOpacityDiv>
            </div>
        </StyledRow>
    )
}

export default TopLevelJobMatchTypeSelection