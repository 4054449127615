import React, { useEffect, useRef, useState } from 'react'
import AuthModalDiv from '../../auth/components/AuthModalDiv'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import CloseButton from '../../shared/components/CloseButton';
import { setActiveChat, setCurrentChatAction, setVoiceInterviewActive } from '../../redux/reducers/appReducer';
import { Avatar, Typography } from '@mui/material';
import Interview, { InterviewState } from '../../shared/models/Interview';
import InterviewType from '../../shared/enums/InterviewType';
import AppLogoRounded from '../../assets/images/app_logo_rounded';
import { AppColors } from '../../theme/AppTheme';
import PrimaryButton from '../../shared/components/PrimaryButton';
import { act } from 'react-dom/test-utils';
import useSpeechToText, { ResultType } from 'react-hook-speech-to-text';
import { useIsArcBrowser } from '../components/ChatBar';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import { AddInterviewAnswerAction } from '../../redux/actions/InterviewActions';
import { MicRounded } from '@mui/icons-material';
import { Bars } from 'react-loader-spinner';
import AppLogoBackground from '../../assets/images/app_logo_bg';

const VoiceInterviewModal = () => {
    const dispatch = useAppDispatch();
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const activeChat = useAppSelector(store => store.appState.activeChat);
    const [activeQuestion, setCurrentQuestion] = useState<number>((activeChat?.interview?.answers?.length ?? 0));
    const [currentAnswerText, setCurrentAnswerText] = useState<string>('');
    const [inputBeforeRecording, setInputBeforeRecording] = useState('');
    const user = useAppSelector(store => store.userState.currentUser);
    const [answerText, setAnswerText] = useState<string>('');

    const [audioSrc, setAudioSrc] = useState<HTMLAudioElement | null>(null);
    const [waitingForAnswer, setWaitingForAnswer] = useState(false);
    const [done, setDone] = useState(false);
    const [botIsSpeaking, setBotIsSpeaking] = useState(false);
    const [userIsSpeaking, setUserIsSpeaking] = useState(false);
    const [speakingTimeout, setSpeakingTimeout] = useState<NodeJS.Timeout | null>(null);

    const [currentInterviewMode, setCurrentInterviewMode] = useState<InterviewState | undefined>(activeChat?.interview?.state);

    // SPEECH TO TEXT

    const isArc = useIsArcBrowser();

    const {
        error,
        interimResult,
        isRecording,
        results,
        startSpeechToText,
        stopSpeechToText,
    } = useSpeechToText({
        continuous: true,
        useLegacyResults: false,
        crossBrowser: true,
        googleApiKey: process.env.REACT_APP_GOOGLE_TTS_API_KEY,
        useOnlyGoogleCloud: isArc,
    });
    useEffect(() => {
        if (error) {
            console.error(`Recording Error: ${error}`);
        }
    }, [error]);

    useEffect(() => {
        setUserIsSpeaking(isRecording);
        setCurrentAnswerText(inputBeforeRecording + (interimResult ?? ''));
        if (speakingTimeout) {
            clearTimeout(speakingTimeout);
        }
        setSpeakingTimeout(setTimeout(() => {
            setUserIsSpeaking(false);
        }, 800));
    }, [interimResult]);

    useEffect(() => {
        if (results.length > 0) {
            const result = results[results.length - 1] as ResultType;
            setCurrentAnswerText((current) => current + (result?.transcript ?? ''));
            setInputBeforeRecording((current) => current + (result?.transcript ?? ''));
        }
    }, [results]);

    // END SPEECH TO TEXT

    const didRunEffectRef = useRef(false);

    useEffect(() => {
        // If it's already set, just return (i.e., don't run the effect again)

        if (currentInterviewMode === InterviewState.modeSelected) {
            if (activeChat?.interview?.state !== InterviewState.modeSelected) {
                setCurrentInterviewMode(InterviewState.started);
            } else {
                return;
            }
        }

        if (didRunEffectRef.current) return;

        // Mark it as run
        didRunEffectRef.current = true;

        if ((activeChat?.interview?.answers?.length ?? 0) > 0) {
            setCurrentQuestion(activeChat!.interview!.answers!.length + 1);
            handleSynthesize(`Welcome back! Let's continue with the interview. Here's the next question. ${activeChat!.interview!.questions![activeChat!.interview!.answers!.length]}`);
            return;
        }

        if (activeChat?.interview) {
            const interview = activeChat.interview;
            if ((interview.answers?.length ?? 0) === 0) {
                if (audioSrc) {
                    audioSrc.pause();
                    audioSrc.src = '';
                }
                handleSynthesize(
                    `Hello, I'll be conducting an interview ${spokenInterviewIntro(
                        interview
                    )}. Let me know when you are ready to begin...`
                );
            }
        }
    }, [activeChat])

    useEffect(() => {
        if (!activeChat) return;

        if (waitingForAnswer) {
            setWaitingForAnswer(false);
            setCurrentAnswerText('');
            setInputBeforeRecording('');
            readNextQuestion(true);
        }
    }, [activeChat])

    const apiKey = process.env.REACT_APP_GOOGLE_TTS_API_KEY;

    const handleSynthesize = async (text: string) => {
        const requestBody = {
            input: { text },
            voice: { languageCode: 'en-US', name: 'en-US-Wavenet-D', ssmlGender: 'NEUTRAL' },
            audioConfig: { audioEncoding: 'MP3' },
        };

        try {
            const response = await fetch(
                `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            const data = await response.json();

            if (data.audioContent) {
                if (audioSrc) {
                    audioSrc.pause();
                    audioSrc.src = '';
                }
                const audio = new Audio(`data:audio/mp3;base64,${data.audioContent}`);
                setAudioSrc(audio);
                audio.play();
                setBotIsSpeaking(true);
                audio.onended = () => {
                    setBotIsSpeaking(false);
                };
            } else {
                console.error('Error synthesizing speech:', data);
                alert('Failed to synthesize speech');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred while synthesizing speech');
        }
    };

    const readNextQuestion = (thanks?: boolean, inputText?: string) => {
        if (thanks && (inputText?.length ?? 0) > 0) {
            setWaitingForAnswer(true);
            dispatch(AddInterviewAnswerAction(currentAnswerText));
            return;
        }
        const interview = activeChat?.interview;
        if (!interview) return;

        const questions = interview.questions;
        const answers = interview.answers ?? [];
        if (!questions || !answers) return;

        if (answers.length >= questions.length) {
            setDone(true);
            handleSynthesize(`That's all the questions I have for you. I'm generating a report based on your answers. Press done to close this window and see the report.`);
            return;
        }

        const question = questions[answers.length];
        setCurrentQuestion(answers.length + 1);
        handleSynthesize(`${thanks ? 'Thank you! Here\'s the next question. ' : ''}${question}`);
    }

    const botLogo = () => {
        return (
            <div style={{ position: 'relative', width: '100px', height: '100px' }}>
                {botIsSpeaking && <AppLogoBackground />}

                {botIsSpeaking ? (
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            height: '100px',
                            width: '100px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '50%',
                            padding: '5px',
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                            zIndex: 1,
                        }}
                    >
                        <Bars
                            height="40"
                            width="40"
                            color={AppColors.white}
                            ariaLabel="bars-loading"
                            visible={true}
                        />
                    </div>
                ) : (
                    <AppLogoRounded />
                )}
            </div>
        );
    }

    const userAvatar = () => {
        return (
            <div style={{ position: 'relative', width: '100px', height: '100px' }}>
                <Avatar
                    src={user?.avatarUrl}
                    style={{
                        backgroundColor: AppColors.pink.dark,
                        fontFamily: 'Poppins',
                        fontWeight: '400',
                        fontSize: '40px',
                        borderRadius: '50%',
                        width: '100px',
                        height: '100px',
                    }}
                >
                    {userIsSpeaking ? <> </> : user?.initials ?? ''}
                </Avatar>

                {userIsSpeaking && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            height: '100px',
                            width: '100px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '50%',
                            padding: '5px',
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                            zIndex: 1,
                        }}
                    >
                        <Bars
                            height="40"
                            width="40"
                            color={AppColors.white}
                            ariaLabel="bars-loading"
                            visible={true}
                        />
                    </div>
                )}
            </div>
        );
    }

    const centerComponent = () => {
        const interview = activeChat?.interview;
        if (!interview) return null;

        if (interview.state === InterviewState.modeSelected) {
            return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '40px' }}>
                <Bars
                    height="40"
                    width="40"
                    color={AppColors.pink.dark}
                    ariaLabel="bars-loading"
                    visible={true}
                />
                <Typography variant='h5' style={{ fontSize: '18px', lineHeight: '24px', color: AppColors.grey.darkish }}>Setting up interview...</Typography>
            </div>
        }

        if (done || interview.state === InterviewState.completed) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '40px' }}>
                    {botLogo()}
                    <Typography variant='h5' style={{ fontSize: '18px', lineHeight: '24px', color: AppColors.grey.darkish }}>The interview is complete. Press done to view the results.</Typography>
                </div>
            );
        }

        if ((interview.answers?.length ?? 0) === 0 && activeQuestion === 0) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '40px' }}>
                    {botLogo()}
                    <Typography variant='h5' style={{ textAlign: 'center', fontSize: '18px', lineHeight: '24px', color: AppColors.grey.darkish }}>Let me know when you are ready to begin...</Typography>
                </div>
            );
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0px', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                {isRecording ? userAvatar() : botLogo()}
                {!isRecording && <Typography variant='subtitle1' style={{
                    fontWeight: 500, lineHeight: '31px', fontSize: '18px', marginTop: '40px'
                }}>
                    Question {activeQuestion} of {interview?.questions?.length ?? 0}:
                </Typography>}
                <Typography variant='h6' style={{ fontWeight: 400, fontSize: '18px', lineHeight: '28px', marginTop: isRecording ? '40px' : '8px', color: AppColors.grey.darkish, }}>
                    {isRecording ? 'Listening to your response...' : interview?.questions?.[activeQuestion - 1]}
                </Typography>
            </div>
        )
    }

    const bottomComponent = () => {
        const interview = activeChat?.interview;
        if (!interview || interview.state === InterviewState.modeSelected) return null;

        if (done || interview.state === InterviewState.completed) {
            return (
                <div style={{ width: '200px' }}>
                    <PrimaryButton height='50px' title='DONE' onClick={() => {
                        if (audioSrc) {
                            audioSrc.pause();
                            audioSrc.src = '';
                        }
                        dispatch(setVoiceInterviewActive(false));
                    }
                    } />
                </div>
            );
        }

        if ((interview.answers?.length ?? 0) === 0 && activeQuestion === 0) {
            return <div style={{ width: '200px' }}>
                <PrimaryButton height='50px' title='BEGIN INTERVIEW' onClick={() => readNextQuestion()} />
            </div>
        }
        if (!isRecording) {
            return (
                <div style={{ width: '200px' }}>
                    <PrimaryButton height='50px' disabled={botIsSpeaking} title='ANSWER QUESTION' onClick={() => {
                        setCurrentAnswerText('');
                        setInputBeforeRecording('');
                        if (audioSrc) {
                            audioSrc.pause();
                            audioSrc.src = '';
                        }
                        startSpeechToText();
                    }} />
                </div>
            )
        } else {
            return (
                <div style={{ width: '200px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <PrimaryButton height='50px' title='Done' disabled={botIsSpeaking} onClick={() => {
                        setAnswerText(currentAnswerText.length > 0 ? currentAnswerText : inputBeforeRecording);
                        readNextQuestion(true, currentAnswerText);
                        stopSpeechToText();
                    }} />
                    <ClickableOpacityDiv onClick={() => {
                        setCurrentAnswerText('');
                        setInputBeforeRecording('');
                        stopSpeechToText();
                        handleSynthesize('Let\'s try that again. Press answer when you\'re ready');
                    }} style={{ position: 'absolute', bottom: '30px', left: '50%', transform: 'translateX(-50%)' }}>
                        <Typography variant='body1' style={{ color: AppColors.pink.dark, fontWeight: 600, fontSize: '16px', lineHeight: '20px', marginTop: '22px' }}>
                            Start again
                        </Typography>
                    </ClickableOpacityDiv>
                </div>
            )
        }
    }

    return (
        <AuthModalDiv isMobile={isMobile}>
            <CloseButton lower onClick={() => {
                if (audioSrc) {
                    audioSrc.pause();
                    audioSrc.src = '';
                }
                if (isRecording) {
                    stopSpeechToText();
                }
                dispatch(setVoiceInterviewActive(false));
            }} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', gap: '15px', height: '100%', padding: isMobile ? '40px 0px' : undefined }}>
                <Typography variant='h2' style={{ textAlign: 'center', height: '40%' }} >Interview:<br />
                    <span style={{ fontWeight: 300, lineHeight: '29px' }}>{voiceInterviewIntro(activeChat?.interview)}</span>
                </Typography>
                <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                    {centerComponent()}
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    {bottomComponent()}
                </div>
            </div>
        </AuthModalDiv>
    )
}

const voiceInterviewIntro = (interview?: Interview): string => {
    if (!interview) return '';

    switch (interview.type) {
        case InterviewType.resume:
            return `Based on your resume`;
        case InterviewType.job:
            return `${interview.jobTitle} @ ${interview.jobCompany}`;
        case InterviewType.specified:
            return interview.userSpecifiedRole ?? '';
    }

    return '';
}

const spokenInterviewIntro = (interview?: Interview): string => {
    if (!interview) return '';

    switch (interview.type) {
        case InterviewType.resume:
            return `based on your resume`;
        case InterviewType.job:
            return `for the job ${interview.jobTitle} @ ${interview.jobCompany}`;
        case InterviewType.specified:
            return `for the role ${interview.userSpecifiedRole ?? ''}`;
    }

    return '';
}


export default VoiceInterviewModal