import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Bars } from 'react-loader-spinner';
import { useAppSelector } from '../../redux/hooks';
import skillsService from '../../services/skillsService';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import { AppColors, BoxShadow } from '../../theme/AppTheme';
import SkillsArray from './SkillsArray';

const Container = styled('div')((props: { isMobile: boolean }) => ({
    position: 'absolute',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: props.isMobile ? 'calc(100vw - 40px)' : '80%',
    height: '60%',
    minHeight: '275px',
    overflowY: 'hidden',
    backgroundColor: 'white',
    padding: '15px',
    boxShadow: BoxShadow,
    zIndex: 100,
    borderRadius: '12px',
}));

const TopBar = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    marginBottom: '110px'
});

interface SkillsSearchProps {
    searchText: string;
    selectedSkills: string[];
    onDone: () => void;
    onSelectSkill: (skill: string) => void;
    onRemoveSkill: (skill: string) => void;
    anchorElement: HTMLElement | null;
    scrollContainer: HTMLElement | null;
}

const SkillsSearch = (props: SkillsSearchProps) => {
    const { searchText, scrollContainer, anchorElement, selectedSkills, onRemoveSkill, onSelectSkill, onDone } = props;

    const [filteredSkills, setFilteredSkills] = useState<string[]>([]);
    const [fetchingSkills, setFetchingSkills] = useState(false);
    const [searchTextTimeout, setSearchTextTimeout] = useState<NodeJS.Timeout>();
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const containerRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        if (searchTextTimeout) {
            clearTimeout(searchTextTimeout);
        }
        setSearchTextTimeout(setTimeout(() => {
            fetchSkills();
        }, 400));
    }, [searchText]);

    useLayoutEffect(() => {
        if (containerRef.current && scrollContainer) {
            const container = containerRef.current;

            container.style.left = `50%`;
            container.style.transform = `translateX(-50%)`;

            if (anchorElement) {
                const anchorRect = anchorElement.getBoundingClientRect();
                const scrollContainerRect = scrollContainer.getBoundingClientRect();
                const topPosition =
                    anchorRect.top + scrollContainer.scrollTop - scrollContainerRect.top - anchorRect.height;
                container.style.top = `${topPosition}px`;
            }
        }
    }, [anchorElement, scrollContainer, containerRef]);

    const fetchSkills = async () => {
        // Add a cancel token to the fetchSkillsForSearch method if the search text changes while its fetching
        setFetchingSkills(true);
        const skills = await skillsService.fetchSkillsForSearch(searchText);
        setFilteredSkills(skills.map(skill => skill.name));
        setFetchingSkills(false);
    }

    const onSkillSelected = (skill: string) => {
        if (selectedSkills.includes(skill)) {
            onRemoveSkill(skill);
        } else {
            onSelectSkill(skill);
        }
    }

    const EmptyView = (): ReactNode => {
        let message = 'No results found';

        if (searchText.length === 0) {
            message = 'Search for skills to add';
        }
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
            }}>
                <Typography variant='body1' style={{ color: AppColors.grey.darkish }}>{message}</Typography>
            </div>
        )
    }

    return (
        <Container ref={containerRef} isMobile={isMobile} onClick={(e) => e.stopPropagation()} >
            <TopBar>
                <Typography variant='body2'>Add your skills below</Typography>
                <ClickableOpacityDiv onClick={onDone}>
                    <Typography variant='subtitle1'
                        style={{
                            color: AppColors.pink.dark,
                            fontWeight: 400,
                        }}>
                        Done
                    </Typography>
                </ClickableOpacityDiv>
            </TopBar>
            {fetchingSkills ?
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                }}>
                    <Bars
                        height="30"
                        width="30"
                        color={AppColors.pink.dark}
                        ariaLabel="bars-loading"
                        visible={true}
                    />
                </div>
                : (filteredSkills.length === 0) ? EmptyView()
                    : <SkillsArray
                        noExpand
                        emptyStateText=''
                        title=''
                        skills={filteredSkills}
                        allSelected={false}
                        selectedSkills={selectedSkills}
                        onSelected={onSkillSelected}
                        onRemove={onRemoveSkill}
                    />}
        </Container>
    )
}

export default SkillsSearch