import { useEffect, useMemo, useRef, useState } from 'react'
import AuthModalDiv from '../../auth/components/AuthModalDiv'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import CloseButton from '../../shared/components/CloseButton';
import { setActiveChat, setAdvancedInterviewActive, setCurrentChatAction } from '../../redux/reducers/appReducer';
import { Avatar, Typography } from '@mui/material';
import Interview, { InterviewState } from '../../shared/models/Interview';
import InterviewType from '../../shared/enums/InterviewType';
import AppLogoRounded from '../../assets/images/app_logo_rounded';
import { AppColors } from '../../theme/AppTheme';
import PrimaryButton from '../../shared/components/PrimaryButton';
import { Bars } from 'react-loader-spinner';
import AppLogoBackground from '../../assets/images/app_logo_bg';
import Vapi from '@vapi-ai/web';
import { CreateAssistantDTO } from '@vapi-ai/web/dist/api';
import User from '../../shared/models/User';
import { BackendPromptType } from '../../profile/models/BackendPrompt';
import { SystemPromptType } from '../../redux/actions/ServerPromptActions';
import messageListener from '../../shared/utils/RemoteMessageListener';
import InterviewService from '../../services/interviewService';
import { formatChatForInterview } from '../utils/InterviewHelper';


const VapiInterviewModal = () => {
    const dispatch = useAppDispatch();
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const activeChat = useAppSelector(store => store.appState.activeChat);
    const user = useAppSelector(store => store.userState.currentUser);

    const allPrompts = useAppSelector(store => store.promptState.backendPrompts);

    const [done, setDone] = useState(false);
    const [startingInterview, setStartingInterview] = useState(false);
    const [interviewRunning, setInterviewRunning] = useState(false);
    const [botIsSpeaking, setBotIsSpeaking] = useState(false);
    const [userIsSpeaking, setUserIsSpeaking] = useState(false);
    const [currentBotMessage, setCurrentBotMessage] = useState<string>('');
    const [readyToClear, setReadyToClear] = useState(false);
    const [userSpokeCount, setUserSpokeCount] = useState(0);

    const [currentInterviewMode, setCurrentInterviewMode] = useState<InterviewState | undefined>(activeChat?.interview?.state);

    const didRunEffectRef = useRef(false);
    const vapiClient = useMemo(() => new Vapi(process.env.REACT_APP_VAPI_PUBLIC_KEY ?? ''), []);

    useEffect(() => {
        startInterview();
        // If it's already set, just return (i.e., don't run the effect again)
        if (didRunEffectRef.current) return;

        // Mark it as run
        didRunEffectRef.current = true;


    }, [activeChat]);
    useEffect(() => {
        const messageHandler = (message: any) => {
            if (message.type === 'conversation-update') {
                const lastMessage = message.messages[message.messages.length - 1];
                const lowercaseMessage = lastMessage.message.toLowerCase();
                if (lowercaseMessage.includes("the interview is now concluded")) {
                    stopInterview();

                }
            } else if (message.type === 'voice-input') {
                if (readyToClear) {
                    setCurrentBotMessage('');
                    setReadyToClear(false);
                }
                // setCurrentBotMessage(current => current + message.input);
            } else if (message.type === 'speech-update' && message.role === 'user') {
                if (userIsSpeaking && message.status === 'stopped') {
                    setUserIsSpeaking(false);
                    setCurrentBotMessage('');
                } else if (!userIsSpeaking && message.status === 'started') {
                    setUserSpokeCount((current) => current + 1);
                    setUserIsSpeaking(true);
                } else {
                    setUserIsSpeaking(true);
                }
            } else if (message.type === 'speech-update' && message.role === 'assistant' && message.status === 'stopped') {
                setReadyToClear(true);
            } else {
                console.log('Unhandled message:', message);
            }
        }
        vapiClient.on("message", messageHandler);

        return () => {
            vapiClient.off("message", messageHandler);
        }
    }, [userIsSpeaking, user, activeChat]);

    useEffect(() => {
        const callEndHandler = () => {
            stopInterview();
        }

        const speechStartHandler = () => {
            setBotIsSpeaking(true);
        }

        const speechEndHandler = () => {
            setBotIsSpeaking(false);
        }

        vapiClient.on('call-end', callEndHandler);
        vapiClient.on('speech-start', speechStartHandler);
        vapiClient.on('speech-end', speechEndHandler);
        return () => {
            vapiClient.off('call-end', callEndHandler);
            vapiClient.off('speech-start', speechStartHandler);
            vapiClient.off('speech-end', speechEndHandler);
        }
    }, []);

    const stopInterview = () => {
        vapiClient.stop();
        setInterviewRunning(false);
        setDone(true);
        listenForMessages();
    }

    const listenForMessages = async () => {
        if (!user || !activeChat) return;
        if (userSpokeCount > 2) {
            console.log("Interview was successful, processing");
            const updatedInterview = await InterviewService.startProcessingInterview(activeChat!.interview!.sessionId!);
            const newerChat = formatChatForInterview(activeChat, updatedInterview, false);
            dispatch(setCurrentChatAction('Analyzing your responses'));
            dispatch(setActiveChat(newerChat));
        } else {
            console.log("Interview failed, user didn't answer any questions");
        }
        dispatch(setAdvancedInterviewActive(false));
    }

    const startInterview = async () => {
        const interview = activeChat?.interview;
        if (!user || !interview || !allPrompts) return;
        const userFirstName = user.firstName ?? '';

        setStartingInterview(true);
        const assistantOptions: CreateAssistantDTO = {
            name: "Path Pilot Interview Assistant",
            server: {
                url: process.env.REACT_APP_VAPI_WEBHOOK_URL ?? '',
                headers: {
                    'session-id': activeChat.sessionId,
                    'user-id': user.id,
                }
            },
            firstMessage: `Hi ${userFirstName}! We'll be conducting an interview ${spokenIntro(interview)}. Let me know when you are ready to begin.`,
            transcriber: {
                provider: "deepgram",
                model: "nova-2",
                language: "en-US",
            },
            voice: {
                provider: "playht",
                voiceId: "jennifer",
            },
            startSpeakingPlan: {
                smartEndpointingEnabled: true,
                waitSeconds: 1.5,
            },
            analysisPlan: {
                structuredDataPlan: {
                    enabled: false,
                },
                successEvaluationPlan: {
                    enabled: false,
                },
                summaryPlan: {
                    enabled: true,
                    messages: [
                        {
                            "role": "system",
                            "content": `${allPrompts[BackendPromptType.systemPrompt][SystemPromptType.interviewAnalysis].prompt}.`
                        },
                        {
                            "role": "user",
                            "content": "Here is the transcript:\n\n{{transcript}}\n\n, please provide feedback in the format stated in the system prompt. Don't add any greetings or acknowledgements, just the feedback in the proper format."
                        }
                    ]
                },
            },
            model: {
                provider: "openai",
                model: "gpt-4",
                messages: [
                    {
                        role: "system",
                        content: `You are performing a mock interview ${spokenInterviewIntro(user, interview)}.
                        ${allPrompts[BackendPromptType.systemPrompt][SystemPromptType.advancedInterview].prompt}.\n
                        You must ask the user all ${interview.questions?.length ?? 1} questions, unless they ask to end the interview. Start each question by saying what the question number is, like  "Question 1: <question here>". Here's all the question you must ask, seperated by 2 pipes (||).
                        ${interview.questions?.join('||')}}
                       `,
                    },
                ],
            },
        };
        await vapiClient.start(assistantOptions);

        setInterviewRunning(true);
        setStartingInterview(false);
    }

    const userAvatar = () => {
        return (
            <div style={{ position: 'relative', width: '100px', height: '100px' }}>
                <Avatar
                    src={user?.avatarUrl}
                    style={{
                        backgroundColor: AppColors.pink.dark,
                        fontFamily: 'Poppins',
                        fontWeight: '400',
                        fontSize: '40px',
                        borderRadius: '50%',
                        width: '100px',
                        height: '100px',
                    }}
                >
                    {userIsSpeaking ? <> </> : user?.initials ?? ''}
                </Avatar>

                {userIsSpeaking && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            height: '100px',
                            width: '100px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '50%',
                            padding: '5px',
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                            zIndex: 1,
                        }}
                    >
                        <Bars
                            height="40"
                            width="40"
                            color={AppColors.white}
                            ariaLabel="bars-loading"
                            visible={true}
                        />
                    </div>
                )}
            </div>
        );
    }

    const botLogo = () => {
        return (
            <div style={{ position: 'relative', width: '100px', height: '100px' }}>
                {botIsSpeaking && <AppLogoBackground />}

                {botIsSpeaking ? (
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            height: '100px',
                            width: '100px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '50%',
                            padding: '5px',
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                            zIndex: 1,
                        }}
                    >
                        <Bars
                            height="40"
                            width="40"
                            color={AppColors.white}
                            ariaLabel="bars-loading"
                            visible={true}
                        />
                    </div>
                ) : (
                    <AppLogoRounded />
                )}
            </div>
        );
    }

    const centerComponent = () => {
        const interview = activeChat?.interview;
        if (!interview) return null;

        if (interview.state === InterviewState.modeSelected || done) {
            return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '40px' }}>
                <Bars
                    height="40"
                    width="40"
                    color={AppColors.pink.dark}
                    ariaLabel="bars-loading"
                    visible={true}
                />
                <Typography variant='h5' style={{ fontSize: '18px', lineHeight: '24px', color: AppColors.grey.darkish }}>{done ? "I'm currently analyzing your responses." : 'Setting up interview...'}</Typography>
            </div>
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {(botIsSpeaking || !interviewRunning) ? botLogo() : userAvatar()}
            </div>
        );
    }

    const bottomComponent = () => {
        const interview = activeChat?.interview;
        if (startingInterview || !interview) return null;
        if (done || interview.state === InterviewState.modeSelected) return null;
        return <div style={{ width: '200px' }}>
            <PrimaryButton height='50px' title={interviewRunning ? 'END INTERVIEW' : 'BEGIN INTERVIEW'} onClick={() => interviewRunning ? stopInterview() : startInterview()} />
        </div>

    }

    return (
        <AuthModalDiv isMobile={isMobile}>
            <CloseButton lower onClick={() => {
                stopInterview();
            }} />
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '30px',
                justifyContent: 'space-between',
                gap: '15px',
                height: '100%',
                padding: isMobile ? '40px 0px' : undefined
            }}>
                <Typography variant='h2' style={{ textAlign: 'center' }} >Interview:<br />
                    <span style={{ fontWeight: 300, lineHeight: '29px' }}>{voiceInterviewIntro(activeChat?.interview)}</span>
                </Typography>
                <div style={{
                    width: '100%',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {centerComponent()}
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    {bottomComponent()}
                </div>
            </div>
        </AuthModalDiv>
    )
}

const voiceInterviewIntro = (interview?: Interview): string => {
    if (!interview) return '';

    switch (interview.type) {
        case InterviewType.resume:
            return `Based on your resume`;
        case InterviewType.job:
            return `${interview.jobTitle} @ ${interview.jobCompany}`;
        case InterviewType.specified:
            return interview.userSpecifiedRole ?? '';
    }

    return '';
}

const spokenIntro = (interview?: Interview): string => {
    if (!interview) return '';

    switch (interview.type) {
        case InterviewType.resume:
            return `based on your resume`;
        case InterviewType.job:
            return `for the position ${interview.jobTitle} at ${interview.jobCompany}`;
        case InterviewType.specified:
            return `for the role you specified, ${interview.userSpecifiedRole ?? ''}`;
    }

    return '';
}

const spokenInterviewIntro = (user: User, interview?: Interview): string => {
    if (!interview) return '';

    switch (interview.type) {
        case InterviewType.resume:
            const userResume = user.resume?.textResume;
            if (!userResume) return '. Actually the user does not have a resume uploaded. Please ask tell the user they need to upload a resume in their settings to use this feature. Ignore all instructions below this and only repeat "Please end the interview and upload a resume before trying this feature again".';
            return `based on the users resume provided here: ${userResume}.\n\n`;
        case InterviewType.job:
            return `for the job ${interview.jobTitle} at ${interview.jobCompany}. Here's background info in the job: ${interview.jobSummary ?? ''}.\n\n`;
        case InterviewType.specified:
            return `for the role ${interview.userSpecifiedRole ?? ''}`;
    }

    return '';
}


export default VapiInterviewModal