import { ReactNode } from "react";

enum InterviewType {
    resume = 'resume',
    job = 'job',
    specified = 'specified',
}

export enum InterviewMode {
    text = 'text',
    voice = 'voice',
    conversation = 'conversation',
}

export const textForInterviewType = (type: InterviewType): ReactNode => {
    switch (type) {
        case InterviewType.resume:
            return <>I would like to do a general interview based on my <b>current resume</b></>
        case InterviewType.job:
            return <>I would like to do an interview for one of my <b>saved jobs</b></>
        case InterviewType.specified:
            return <>I would like to do an interview for a <b>specific type of job / role</b> I'm interested in</>
    }
}

export const textForInterviewMode = (type: InterviewMode): ReactNode => {
    switch (type) {
        case InterviewMode.text:
            return <>I would like to use <b>Text</b></>
        // I'll ask you quetions and youc an provide me answers via text.
        case InterviewMode.voice:
            return <>I would like to use <b>Basic Voice</b></>
        // I'll ask you questions and you can provide me answers via voice.
        case InterviewMode.conversation:
            return <>I would like to use <b>Advanced Voice</b></>
    }
}

export default InterviewType;