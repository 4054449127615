import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Bars } from 'react-loader-spinner';
import CloseIcon from '../../assets/icons/close_icon';
import SavedIcon from '../../assets/icons/save_icon_selected';
import SaveIcon from '../../assets/icons/save_icon_unselected';
import { generatePromptInfo } from '../../profile/models/UserInfo';
import { sendMessageAction } from '../../redux/actions/ChatActions';
import { ClearAllFocusedAction } from '../../redux/actions/GlobalActions';
import { StartInterviewForJobAction } from '../../redux/actions/InterviewActions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setCurrentChatAction, setSelectedTab, toggleFollowChat } from '../../redux/reducers/appReducer';
import { addSavedJob, expireJob, removeSavedJob } from '../../redux/reducers/savedJobsReducer';
import { addJobView } from '../../redux/reducers/viewedJobsReducer';
import JobsService from '../../services/jobsService';
import JobViewService from '../../services/jobViewService';
import suggestedPromptsService, { SuggestedPrompt } from '../../services/suggestedPromptsService';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import NotificationBar, { NotificationType } from '../../shared/components/NotificationBar';
import OutlinedButton from '../../shared/components/OutlinedButton';
import PrimaryButton from '../../shared/components/PrimaryButton';
import SuggestionRow from '../../shared/components/SuggestionRow';
import Job from '../../shared/models/Job';
import zIndicies from '../../shared/utils/zIndexConstants';
import { AppColors, sharpTransition } from '../../theme/AppTheme';
import JobInfoRow from '../components/JobInfoRow';
import JobSummaryResponse, { JobSummaryWidget } from '../models/JobSummaryResponse';
import './JobDetails.css';
import SkillsArray from '../../mainTabs/profile/SkillsArray';
import { addJobDescription } from '../../redux/reducers/userReducer';
import { MainTabType } from '../../navigation/classes/MainTab';
import { MessageMetadata } from '../../shared/models/Message';



const DetailsPage = styled('div')((props: { position: number, fixed: boolean, isMobile: boolean }) => ({
    position: props.fixed ? 'absolute' : 'relative',
    right: props.fixed ? -props.position : undefined,
    top: props.fixed ? 0 : undefined,
    bottom: props.fixed ? 0 : undefined,
    backgroundColor: AppColors.white,
    transition: `right 0.2s ${sharpTransition}`,
    width: props.isMobile ? props.fixed ? '98%' : '100%' : (props.fixed ? 'clamp(500px, 58vw, 1200px)' : '100%'),
    overflowY: 'hidden',
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const ScrollableContainer = styled('div')((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingLeft: props.isMobile ? '40px' : 'clamp(24px, 6vw, 145px)',
    paddingRight: props.isMobile ? '40px' : 'clamp(24px, 6vw, 145px)',
    paddingTop: props.isMobile ? '30px' : '54px',
    alignItems: 'center',
    width: props.isMobile ? 'calc(100% - 100px)' : '100%',
    height: props.isMobile ? 'calc(100% - 50px)' : '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    paddingBottom: '50px',
    zIndex: zIndicies.jobFlyout,
}));

const StyledButtons = styled('div')((props: { isMobile: boolean }) => ({
    padding: '10px',
    borderTop: `1px solid ${AppColors.grey.border}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '15px',
    width: props.isMobile ? 'calc(100% - 20px)' : '100%',
    backgroundColor: AppColors.white,
    marginBottom: 'env(safe-area-inset-bottom)',
}));

const StyledTopBar = styled('div')((props: { isMobile: boolean, animated: boolean }) => ({
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    height: props.isMobile ? '50px' : '80px',
    width: props.isMobile ? 'calc(100% - 68px)' : '100%',
    display: (props.isMobile && !props.animated) ? 'none' : 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: props.isMobile ? '15px' : '34px',
    paddingBottom: props.isMobile ? '10px' : '20px',
    paddingLeft: '34px',
    paddingRight: '34px',
    backgroundColor: AppColors.white,
    zIndex: props.isMobile ? zIndicies.flyoutPanelMobile : zIndicies.flyoutPanelDesktop,
}));

interface JobDetailsContentPageProps {
    job: Job;
    animated: boolean;
    onClose?: () => void;
}

const JobDetailsContentPage = (props: JobDetailsContentPageProps) => {
    const { job, onClose, animated } = props;
    const savedJobs = useAppSelector(store => store.savedJobsState.jobsArray ?? []);
    const viewedJobs = useAppSelector(store => store.viewedJobsState.viewedJobs);
    const isSaved = savedJobs.some(j => (j.id === job.jobId) || (j.url === job.url));
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const jobModel = useAppSelector(store => store.appState.jobSummaryModel);
    const jobSummaryPreamble = useAppSelector(store => store.appState.jobSummaryPreamble);
    const jobSummaryMessage = useAppSelector(store => store.appState.jobSummaryMessage);
    const currentUser = useAppSelector((s) => s.userState.currentUser);
    const [position, setPosition] = useState(animated ? 800 : 0);
    const [bookmarked, setBookmarked] = useState(isSaved);
    const [loadingUrl, setLoadingUrl] = useState(false);
    const [expired, setExpired] = useState(job.expired ?? false);
    const [notificationBarOpen, setNotificationBarOpen] = useState(false);
    const [shouldCloseNotification, setShouldCloseNotification] = useState(false);
    const allPrompts = useAppSelector(store => store.promptState.backendPrompts);

    const [fetchingSummary, setFetchingSummary] = useState(false);
    const [jobSummary, setJobSummary] = useState<JobSummaryResponse | undefined>();

    const dispatch = useAppDispatch();

    const fetchSummary = async (signal: AbortSignal) => {
        try {
            const enhancedJobMessage = injectJobInfoIntoPrompt(jobSummaryMessage);
            const jobDescription = await JobsService.fetchSummary(job, jobModel, enhancedJobMessage, jobSummaryPreamble, signal);
            if (jobDescription) {
                dispatch(addJobDescription(jobDescription));
                const summary = jobDescription?.description;
                const cleanSummary = summary.replace(/json/g, '');
                const jobSummaryResponse: JobSummaryResponse = JSON.parse(cleanSummary);
                setJobSummary(jobSummaryResponse);
            }
        } catch (error) {
            console.error('Error fetching job summary:', error)
        } finally {
            setFetchingSummary(false);
        }
    }

    useEffect(() => {
        recordJobView();
        let abortController = new AbortController();
        setPosition(0);
        if (!expired && !fetchingSummary && !jobSummary) {
            setFetchingSummary(true);
            fetchSummary(abortController.signal);
        } else {
            setFetchingSummary(false);
        }

        return () => {
            abortController.abort();
        }
    }, []);

    useEffect(() => {
        let abortController = new AbortController();
        setExpired(job.expired ?? false);
        setJobSummary(undefined);
        setFetchingSummary(true);
        fetchSummary(abortController.signal);
        return () => {
            abortController.abort();
        }
    }, [job]);

    const recordJobView = async () => {
        if (viewedJobs[job.jobId ?? job.id] === undefined) {
            try {
                const view = await JobViewService.recordJobView(job.jobId ?? `${job.id}`);
                if (view) {
                    dispatch(addJobView(view));
                }
            } catch (error) {
                console.error('Error recording job view:', error);
            }
        }
    }

    const close = () => {
        if (onClose)
            onClose();
        if (animated) {
            setPosition(800);
            setTimeout(() => {
                dispatch(ClearAllFocusedAction());
            }, 200);
        } else {
            dispatch(ClearAllFocusedAction());
        }
    }

    const onApply = async () => {
        setLoadingUrl(true);
        // console.log(`Getting job apply url for ${JSON.stringify(job)}`);
        // const jobUrl = await JobsService.fetchJobApplyUrl(job);
        const jobUrl = job.url;
        if (jobUrl) {
            window.open(jobUrl, '_blank');
        } else {
            await JobsService.expireJob(job);
            dispatch(expireJob(job));
            setExpired(true);
        }
        setLoadingUrl(false);
    }

    const onBookmark = async () => {
        if (!bookmarked) {
            setBookmarked(true);
            const savedJob = await JobsService.saveJob(job);
            dispatch(addSavedJob(job));
            if (!savedJob) {
                setBookmarked(false);
            } else {
                setNotificationBarOpen(true);
            }
        } else {
            await JobsService.unsaveJob(job)
            dispatch(removeSavedJob(job));
            setBookmarked(false);
        }
    }

    const onSuggestionPressed = (suggestion: SuggestedPrompt) => {
        dispatch(setCurrentChatAction(undefined));
        if (suggestion.title === 'interview') {
            dispatch(StartInterviewForJobAction(job));
            dispatch(ClearAllFocusedAction());
        } else {
            const injectedInfoPrompt = injectJobInfoIntoPrompt(suggestion.prompt);
            const injectedPrompt = injectResumeIntoPrompt(injectedInfoPrompt);
            const fullPrompt = `${injectedPrompt}\nJob Description: ${job.summary}\nTitle: ${job.title}\nCompany: ${job.company}\nSkills: ${job.skills}`;
            const isNewChat = suggestion.title === 'interview' || !animated;

            const metadata: MessageMetadata | undefined = suggestion.title === 'upskilling' ? { hideDoc: true } : undefined;

            dispatch(sendMessageAction(fullPrompt, isNewChat, false, metadata));
            dispatch(ClearAllFocusedAction());
            dispatch(toggleFollowChat());
        }
    }

    const injectResumeIntoPrompt = (prompt: string): string => {
        if (prompt.includes("[RESUME_TEXT]") === false) return prompt;
        const resumeText = currentUser?.resume?.textResume;
        if (resumeText) {
            const newPrompt = prompt.replace("[RESUME_TEXT]", resumeText);
            return newPrompt;
        } else {
            const promptNumberRegex = /\[JOB\d+\]/;
            const promptNumberMatch = prompt.match(promptNumberRegex);
            if (promptNumberMatch) {
                const promptNumber = promptNumberMatch[0];
                return `${promptNumber}This user hasn't uplaoded a resume yet. Please tell them "I see you haven't uploaded a resume yet. Please upload your resume in your profile settings so I can help you with this.";`
            }
            return prompt;
        }
    }

    const injectJobInfoIntoPrompt = (prompt: string): string => {
        if (prompt.includes("[DESIRED_INFO]") === false) return prompt;
        const userInfoText = generatePromptInfo(currentUser?.info);
        const newPrompt = prompt.replace("[DESIRED_INFO]", userInfoText);
        return newPrompt;
    }

    const onShowSavedJobs = () => {
        dispatch(setSelectedTab(MainTabType.savedJobs));
    }

    return (
        <DetailsPage position={position} fixed={animated} isMobile={isMobile}>
            <StyledTopBar animated={animated} isMobile={isMobile}>
                {animated && <>
                    <ClickableOpacityDiv style={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: AppColors.white,
                        zIndex: zIndicies.jobCloseButton,
                    }}
                        onClick={close}>
                        <CloseIcon />
                    </ClickableOpacityDiv>

                </>}
                {!expired && (!isMobile || (isMobile && animated)) && (!!(job.jobId || job.url)) && !(expired && !bookmarked) ?
                    <ClickableOpacityDiv
                        onClick={onBookmark}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: zIndicies.jobBookmarkButton,
                        }}
                    >
                        {bookmarked ? <SavedIcon /> : <SaveIcon />}
                    </ClickableOpacityDiv> : <></>}
                {expired && <NotificationBar type={NotificationType.expiredJob}
                    unAnimated
                    marginTop={50}
                    onClose={() => { }}
                    shouldClose={false}
                    message={'This job is no longer available'}
                    onAction={isSaved ? onBookmark : undefined}
                />}
            </StyledTopBar>

            {notificationBarOpen && <NotificationBar
                type={NotificationType.savedJob}
                marginTop={isMobile ? 65 : animated ? 75 : 75}
                onClose={() => {
                    setShouldCloseNotification(false);
                    setNotificationBarOpen(false);
                }}
                onAction={onShowSavedJobs}
                shouldClose={shouldCloseNotification}
            />}
            <ScrollableContainer isMobile={isMobile}>
                {/* Header */}
                <div>
                    {job.companyLogo && <img src={job.companyLogo} alt='Company Logo' style={{ width: '60px', height: '60px', borderRadius: '12px' }} />}
                    <Typography variant='h1' style={{ color: AppColors.black, fontSize: '24px', marginTop: '40px', marginBottom: '12px' }}>
                        {job.company}
                    </Typography>
                    <Typography variant='h5' style={{ color: AppColors.grey.darkish, marginBottom: '40px', }}>
                        {job.title}
                    </Typography>
                </div>
                <JobInfoRow title={'Location'} subtitle={`${job.location1}${job.location2 ? `, ${job.location2}` : ''}`} />
                {jobSummary ? JobSummaryWidget(jobSummary, job.skills.split(',') ?? [])
                    : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '16px 0' }}>
                        <Bars
                            height="30"
                            width="30"
                            color={AppColors.pink.dark}
                            ariaLabel="bars-loading"
                            visible={true}
                        />
                        <Typography variant='h5' style={{ color: AppColors.grey.darkish, marginTop: '8px' }}>
                            Loading personalized job summary
                        </Typography>
                    </div>
                }

                <JobInfoRow
                    title={'Suggested actions'}
                    subtitle={<div style={{ alignItems: 'flex-start', display: 'inline-flex', flexDirection: 'column', gap: '12px', width: 'fit-content' }}>{suggestedPromptsService.getJobPrompts(allPrompts!).map((prompt, index) => ((!expired || (expired && prompt.title === 'expired')) ? <SuggestionRow key={0} title={prompt.description} onClick={() => onSuggestionPressed(prompt)} /> : null))}</div>}
                    hideBottomBorder
                    bigMargin
                    closedHeight={325}
                />
            </ScrollableContainer>
            {!(expired && !bookmarked) && <StyledButtons isMobile={isMobile}>
                {loadingUrl && <Bars
                    height="30"
                    width="25"
                    color={AppColors.pink.dark}
                    ariaLabel="bars-loading"
                    visible={true}
                />}
                {!expired && !loadingUrl && <PrimaryButton title={'View'} disabled={false} onClick={onApply} height={'48px'} style={{ width: '118px' }} />}
                <OutlinedButton title={bookmarked ? (expired ? 'Remove Job' : 'Unsave') : 'Save'} disabled={false} onClick={onBookmark} height={'48px'} style={{ width: '118px' }} />
            </StyledButtons>
            }
        </DetailsPage>
    )
}

export default JobDetailsContentPage