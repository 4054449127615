import styled from '@emotion/styled';
import { Modal, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import CloseIcon from '../../assets/icons/close_icon';
import InterviewIcon from '../../assets/icons/interview_icon';
import SavedIcon from '../../assets/icons/save_icon_selected';
import SaveIcon from '../../assets/icons/save_icon_unselected';
import SavedResourceListDesktopModal from '../../mainTabs/savedResources/SavedResourceListDesktopModal';
import SavedResourceListMobileModal from '../../mainTabs/savedResources/SavedResourceListMobileModal';
import { ClearAllFocusedAction } from '../../redux/actions/GlobalActions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addSavedInterview, clearFocusedInterview, clearSelectedInterview, removeSavedInterview } from '../../redux/reducers/interviewReducer';
import { addResourceToList, addSavedResource, clearFocusedResource, removeSavedResource, toggleResources } from '../../redux/reducers/resourceReducer';
import InterviewService from '../../services/interviewService';
import ResourcesService from '../../services/resourcesService';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import NotificationBar, { NotificationType } from '../../shared/components/NotificationBar';
import OutlinedButton from '../../shared/components/OutlinedButton';
import PrimaryButton from '../../shared/components/PrimaryButton';
import Interview from '../../shared/models/Interview';
import Resource from '../../shared/models/Resource';
import zIndicies from '../../shared/utils/zIndexConstants';
import { AppColors, sharpTransition } from '../../theme/AppTheme';
import DownloadFileModal from '../components/DownloadFileModal';
import UrlsContent from '../components/UrlsContent';
import DocumentContentType from '../enums/DocumentContentType';
import { formattedInterviewText, interviewTitle } from '../utils/InterviewHelper';
import './JobDetails.css';
import ResourceDetailsContentPage from './ResourceDetailsContentPage';



const DetailsPage = styled('div')((props: { position: number, fixed: boolean, isMobile: boolean }) => ({
    position: props.fixed ? 'absolute' : 'relative',
    right: props.fixed ? -props.position : undefined,
    top: props.fixed ? 0 : undefined,
    bottom: props.fixed ? 0 : undefined,
    backgroundColor: AppColors.white,
    transition: `right 0.2s ${sharpTransition}`,
    width: props.isMobile ? props.fixed ? '98%' : '100%' : (props.fixed ? 'clamp(500px, 58vw, 1200px)' : '100%'),
    overflowY: 'hidden',
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
}));

const ScrollableContainer = styled('div')((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingLeft: props.isMobile ? '40px' : 'clamp(24px, 6vw, 145px)',
    paddingRight: props.isMobile ? '40px' : 'clamp(24px, 6vw, 145px)',
    paddingTop: props.isMobile ? '30px' : '54px',
    alignItems: 'flex-start',
    width: props.isMobile ? 'calc(100% - 80px)' : '100%',
    height: props.isMobile ? 'calc(100% - 50px)' : '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingBottom: '50px',
    zIndex: zIndicies.jobFlyout,
    textAlign: 'left',
}));

const StyledButtons = styled('div')((props: { isMobile: boolean }) => ({
    padding: '10px',
    borderTop: `1px solid ${AppColors.grey.border}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '15px',
    width: props.isMobile ? 'calc(100% - 20px)' : '100%',
    backgroundColor: AppColors.white,
    marginBottom: 'env(safe-area-inset-bottom)',
}));

const StyledTopBar = styled('div')((props: { isMobile: boolean, animated: boolean }) => ({
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    width: props.isMobile ? 'calc(100% - 68px)' : '100%',
    display: (props.isMobile && !props.animated) ? 'none' : 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: props.isMobile ? '15px' : '34px',
    paddingBottom: props.isMobile ? '10px' : '20px',
    paddingLeft: '34px',
    paddingRight: '34px',
    backgroundColor: AppColors.white,
    zIndex: props.isMobile ? zIndicies.flyoutPanelMobile : zIndicies.flyoutPanelDesktop,
}));

const Divider = () => {
    return <div style={{ width: '100%', minHeight: '1.5px', backgroundColor: AppColors.separator }} />
}

interface JobDetailsContentPageProps {
    interview: Interview;
    animated: boolean;
    onClose?: () => void;
}

const InterviewContentPage = (props: JobDetailsContentPageProps) => {
    const { interview, onClose, animated } = props;
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const [position, setPosition] = useState(animated ? 800 : 0);
    const [downloadOpen, setDownloadOpen] = useState(false);
    const savedInterviews = useAppSelector(state => state.interviewState.interviewsArray);
    const [isSaved, setIsSaved] = useState(savedInterviews.some(i => i.id === interview?.id));
    const interviewToggle = useAppSelector(state => state.interviewState.interviewToggle);
    const [notifBarOpen, setNotifBarOpen] = useState(false);
    const state = useAppSelector(state => state);

    // Resources
    const focusedResource = useAppSelector((s) => s.resourceState.focusedResource);
    const resourceLists = useAppSelector(store => store.resourceState.resourceList);
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
    const [listModalOpen, setListModalOpen] = useState(false);
    const [notificationBarOpen, setNotificationBarOpen] = useState(false);
    const [shouldCloseNotification, setShouldCloseNotification] = useState(false);
    const [resource, setResource] = useState<Resource | null>(null);

    const dispatch = useAppDispatch();

    useEffect(() => {
        setPosition(0);
    }, []);

    useEffect(() => {
        setIsSaved(savedInterviews.some(i => i.id === interview?.id));
    }, [interviewToggle]);

    const onBookmarkToggled = async () => {
        if (!interview) return;
        if (isSaved) {
            await InterviewService.removeSavedInterview(interview);
            dispatch(removeSavedInterview(interview));
        } else {
            await InterviewService.saveInterview(interview);
            dispatch(addSavedInterview(interview));
            setNotifBarOpen(true);
        }
        setIsSaved(!isSaved);
    }


    const close = () => {
        if (onClose)
            onClose();
        if (animated) {
            setPosition(800);
            setTimeout(() => {
                dispatch(ClearAllFocusedAction());
                dispatch(clearFocusedInterview());
                dispatch(clearSelectedInterview());
            }, 200);
        } else {
            dispatch(ClearAllFocusedAction());
            dispatch(clearFocusedInterview());
            dispatch(clearSelectedInterview());
        }
    }

    const onBookmarkResourcePressed = async (bookmarking: boolean, resource: Resource) => {
        setResource(resource);
        if (bookmarking) {
            const savedResource = await ResourcesService.saveResource(resource);
            if (savedResource) {
                dispatch(addSavedResource(savedResource));
                const favouritesList = resourceLists.find(list => list.name === 'Likes');
                if (favouritesList) {
                    const success = await ResourcesService.addResourceToList(favouritesList.id, savedResource.url);
                    if (success) {
                        dispatch(addResourceToList({ listId: favouritesList.id, resource: savedResource }));
                        setNotificationBarOpen(true);
                    }
                } else {
                    console.error("Couldn't find likes list");
                }
            }
        } else {
            setAnchorElement(document.getElementById(resource.url ?? ''));
            setListModalOpen(true);
        }
    }

    const onListModalClosed = async (shouldDelete: boolean) => {
        setListModalOpen(false);
        setShouldCloseNotification(true);
        if (!resource) return;
        if (shouldDelete) {
            await ResourcesService.unsaveResource(resource)
            dispatch(removeSavedResource(resource));
            dispatch(toggleResources());
        }
    }

    const onShowLists = () => {
        setAnchorElement(document.getElementById('action-btn'));
        setListModalOpen(true);
    }

    const onResourceClose = () => {
        dispatch(clearFocusedResource());
    }

    return (
        <DetailsPage position={position} fixed={animated} isMobile={isMobile}>
            <StyledTopBar animated={animated} isMobile={isMobile}>
                {animated ? <ClickableOpacityDiv style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: AppColors.white,
                    zIndex: zIndicies.jobCloseButton,
                }}
                    onClick={close}>
                    <CloseIcon />
                </ClickableOpacityDiv> : <div />}
                <ClickableOpacityDiv
                    id={'resource-save-btn'}
                    onClick={onBookmarkToggled}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {isSaved ? <SavedIcon /> : <SaveIcon />}
                </ClickableOpacityDiv>
            </StyledTopBar>
            <ScrollableContainer isMobile={isMobile}>
                <Typography variant='h2' style={{ color: AppColors.black, fontSize: isMobile ? '18px' : '24px', lineHeight: isMobile ? '30px' : 'auto', marginBottom: '16px' }}>
                    {interviewTitle(interview, state)}
                </Typography>
                <Typography variant='h2' style={{ fontSize: isMobile ? '14px' : '20px', fontWeight: '300', lineHeight: isMobile ? '26px' : '36px', marginBottom: '30px' }}>
                    View and save your interview below.
                </Typography>
                <Divider />
                <div style={{ minHeight: '30px' }} />
                <Typography
                    variant='h5'
                    sx={{
                        textAlign: 'left',
                        color: AppColors.black,
                        userSelect: 'text',
                        webkitUserSelect: 'text',
                        maxWidth: '100%',
                    }}
                >
                    <ReactMarkdown>
                        {formattedInterviewText(interview)}
                    </ReactMarkdown>
                    {/* {interview.courseUrls && <UrlsContent onBookmarkPressed={onBookmarkResourcePressed} urls={interview.courseUrls} />} */}
                </Typography>
            </ScrollableContainer>
            <StyledButtons isMobile={isMobile}>
                <PrimaryButton title={'Download'} disabled={false} onClick={() => setDownloadOpen(true)} height={'48px'} style={{ width: '118px' }} />
                <OutlinedButton title={isSaved ? 'Unsave' : 'Save'} disabled={false} onClick={onBookmarkToggled} height={'48px'} style={{ width: '130px' }} />
            </StyledButtons>
            {notificationBarOpen && <NotificationBar
                type={NotificationType.resource}
                marginTop={isMobile ? 48 : animated ? 84 : 75}
                onClose={() => {
                    setShouldCloseNotification(false);
                    setNotificationBarOpen(false);
                }}
                onAction={onShowLists}
                shouldClose={shouldCloseNotification}
            />}
            {notifBarOpen && <NotificationBar
                type={NotificationType.message}
                marginTop={isMobile ? 48 : animated ? 84 : 75}
                onClose={() => setNotifBarOpen(false)}
                shouldClose={false}
                message={'Added to Saved Interviews'}
                icon={<InterviewIcon color={AppColors.white} />}
            />}
            {!isMobile && listModalOpen && resource &&
                <SavedResourceListDesktopModal
                    backgroundClicked={onListModalClosed}
                    savedResource={resource}
                    topRight={false}
                    anchorEl={anchorElement}
                />
            }
            {isMobile && listModalOpen && resource &&
                <SavedResourceListMobileModal
                    backgroundClicked={onListModalClosed}
                    savedResource={resource}
                />
            }
            <Modal
                open={downloadOpen}
                onClose={() => setDownloadOpen(false)}
                sx={{ zIndex: 999999, }}
            >
                <DownloadFileModal type={DocumentContentType.interview} content={formattedInterviewText(interview)} title={interviewTitle(interview, state)} onDownloaded={() => setDownloadOpen(false)} />
            </Modal>
            {focusedResource &&
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: zIndicies.flyoutPanelNested,
                }}>
                    <ResourceDetailsContentPage hasBackButton onClose={onResourceClose} resource={focusedResource} animated={false} />
                </div>}
        </DetailsPage>
    )
}

export default InterviewContentPage;