import { auth } from "../../firebaseConfigs";
import UserTypeManager from "../../shared/utils/UserCodeManager";
import { clearActiveChat, clearChatHistory, clearChatHistoryBuckets, clearFocusedJob, setSelectedTab } from "../reducers/appReducer";
import { clearFocusedResume } from "../reducers/documentsReducer";
import { clearSelectedInterview } from "../reducers/interviewReducer";
import { clearFocusedResource, clearSelectedSavedResource } from "../reducers/resourceReducer";
import { clearSelectedJob } from "../reducers/savedJobsReducer";
import { clearSelectedResume } from "../reducers/savedResumeReducer";
import { clearUser, setEmailVerificationSent, setFirebaseUser } from "../reducers/userReducer";
import { AppDispatch } from "../store";

export const LogoutAction = () => {
    return async (dispatch: AppDispatch) => {
        auth.signOut();
        dispatch(clearUser());
        dispatch(clearActiveChat());
        dispatch(clearChatHistory());
        dispatch(clearChatHistoryBuckets());
        dispatch(setSelectedTab(-1));
        dispatch(setEmailVerificationSent(false));
        dispatch(ClearAllFocusedAction());
        dispatch(setFirebaseUser(null));
    };
};

export const ClearAllFocusedAction = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(clearFocusedJob());
        dispatch(clearSelectedJob());
        dispatch(clearFocusedResource());
        dispatch(clearSelectedSavedResource());
        dispatch(clearFocusedResume());
        // dispatch(clearFocusedInterview());
    };
}

export const ClearAllSelectedAction = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(clearSelectedJob());
        dispatch(clearSelectedSavedResource());
        dispatch(clearSelectedInterview());
        dispatch(clearSelectedResume());
    };
}