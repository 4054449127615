import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';
import SaveIconUnselected from '../../assets/icons/save_icon_unselected';
import { useAppSelector } from '../../redux/hooks';
import { AppColors } from '../../theme/AppTheme';
import ClickableOpacityDiv from './ClickableOpacityDiv';
import SavedJobsIcon from '../../assets/icons/saved_jobs_icon';

export enum NotificationType {
    chat,
    resource,
    message,
    expiredJob,
    savedJob,
}

const Container = styled('div')((props: { marginTop?: number, top: number, isMobile: boolean, hasAction: boolean, bgColor?: string }) => ({
    position: 'absolute',
    top: props.top,
    left: 0,
    right: 0,
    padding: props.isMobile ? '0px 20px' : 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: props.isMobile && props.hasAction ? 'space-between' : 'center',
    alignItems: 'center',
    marginTop: props.marginTop,
    height: '50px',
    background: props.bgColor ?? 'linear-gradient(40deg, #96EAF1 0%, #98E3F0 12%, #9FD1EC 30%, #A9B3E5 50%,#B889DD 73%, #CA54D2 100%)',
    transition: 'top 0.3s ease-in-out',
    zIndex: 1002,
}));

interface NotificationBarProps {
    type: NotificationType;
    message?: string;
    marginTop?: number;
    shouldClose: boolean;
    icon?: ReactNode;
    unAnimated?: boolean;
    onClose?: () => void;
    onAction?: () => void;
}

const NotificationBar = (props: NotificationBarProps) => {
    const { type, message, marginTop, shouldClose, unAnimated, onClose, onAction, icon } = props;
    const [top, setTop] = useState(-50);
    const timeoutId = useRef<NodeJS.Timeout | null>(null);
    const isMobile = useAppSelector(state => state.appState.isMobile);
    const listModalOpen = useAppSelector(state => state.resourceState.listModalOpen);

    const animateClose = () => {
        if (!onClose || unAnimated) return;
        setTop(-50);
        setTimeout(() => {
            onClose();
        }, 300);
    }

    useEffect(() => {
        if (unAnimated) return;
        setTimeout(() => {
            setTop(0)
        }, 100);
        startCloseTimer();
    }, []);

    useEffect(() => {
        if (shouldClose) {
            animateClose();
        }
    }, [shouldClose]);

    useEffect(() => {
        if (listModalOpen) {
            stopCloseTimer();
        } else if (!listModalOpen) {
            startCloseTimer();
        }
    }, [listModalOpen]);

    const startCloseTimer = () => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }
        timeoutId.current = setTimeout(() => {
            animateClose();
        }, 5000)
    }

    const stopCloseTimer = () => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }
    }

    const actionClicked = () => {
        if (!onAction) return;
        stopCloseTimer();
        onAction();
    }

    const onMouseEnter = () => {
        stopCloseTimer();
    }

    const onMouseLeave = () => {
        if (listModalOpen) return;
        startCloseTimer();
    }

    const content = (message?: string) => {
        let textContent = message ?? '';
        let showAction = false;
        let actionText = 'View';
        let notifIcon: ReactNode | undefined = <SaveIconUnselected color={AppColors.white} />;
        switch (type) {
            case NotificationType.chat:
                textContent = 'Added to Saved Chats';
                showAction = true;
                break;
            case NotificationType.resource:
                textContent = 'Added to “Liked” resources';
                showAction = true;
                actionText = isMobile ? 'Change' : 'Change List';
                break;
            case NotificationType.message:
                textContent = message ?? '';
                showAction = onAction !== undefined;
                notifIcon = icon;
                break;
            case NotificationType.expiredJob:
                textContent = message ?? '';
                showAction = onAction !== undefined;
                actionText = 'Remove Job';
                break;
            case NotificationType.savedJob:
                textContent = 'Added to Saved Jobs';
                showAction = onAction !== undefined;
                actionText = 'View';
                notifIcon = <SavedJobsIcon color={AppColors.white} />;
                break;
        }

        return (
            <Container bgColor={type === NotificationType.expiredJob ? AppColors.grey.dark : undefined} isMobile={isMobile} top={top} marginTop={marginTop} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} hasAction={showAction}>
                <div style={{ display: 'flex' }}>
                    {notifIcon}
                    <Typography variant='h3' style={{ color: AppColors.white, marginLeft: '10px' }}>
                        {textContent}
                    </Typography>
                </div>
                {showAction &&
                    <>
                        {!isMobile && <div style={{ margin: '0px 15px', width: '1px', height: '21px', backgroundColor: AppColors.white }} />}
                        <ClickableOpacityDiv id={'action-btn'} onClick={actionClicked}>
                            <Typography variant='h3' style={{ color: AppColors.white, textDecoration: 'underline' }}>
                                {actionText}
                            </Typography>
                        </ClickableOpacityDiv>
                    </>
                }
            </Container>
        );
    }

    return (
        <>
            {content(message)}
        </>
    )
}

export default NotificationBar