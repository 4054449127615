
import BackendPrompt, { BackendPromptType } from '../../profile/models/BackendPrompt';
import JobsService from '../../services/jobsService';
import PromptsService from '../../services/promptsService';
import { JobContentPromptType } from '../../services/suggestedPromptsService';
import CoverLetterType from '../../shared/enums/CoverLetterType';
import InterviewQuestionsType from '../../shared/enums/InterviewQuestionsType';
import JobMatchType from '../../shared/enums/JobMatchType';
import ResumeFixType from '../../shared/enums/ResumeType';
import TopLevelJobMatchType from '../../shared/enums/TopLevelJobMatchType';
import UpskillingType from '../../shared/enums/UpskillingType';
import Job from '../../shared/models/Job';
import { setAllPrompts } from '../reducers/promptReducer';
import { setSavedJobBuckets, setSavedJobs } from '../reducers/savedJobsReducer';
import { AppDispatch, RootState } from '../store';


export interface AllPrompts {
    [BackendPromptType.job]: {
        [JobMatchType.resumeAndPreferences]: BackendPrompt;
        [JobMatchType.specificRole]: BackendPrompt;
        [TopLevelJobMatchType.advice]: BackendPrompt;
    };
    [BackendPromptType.resume]: {
        [ResumeFixType.improvements]: BackendPrompt;
        [ResumeFixType.bestPractices]: BackendPrompt;
        [ResumeFixType.savedJobs]: BackendPrompt;
        [ResumeFixType.specificRole]: BackendPrompt;
    };
    [BackendPromptType.coverLetter]: {
        [CoverLetterType.general]: BackendPrompt;
        [CoverLetterType.savedJobs]: BackendPrompt;
        [CoverLetterType.specificRole]: BackendPrompt;
    };
    [BackendPromptType.upskill]: {
        [UpskillingType.resumeAndPreferences]: BackendPrompt;
        [UpskillingType.savedJobs]: BackendPrompt;
        [UpskillingType.specificRole]: BackendPrompt;
    };
    [BackendPromptType.interviewPrep]: {
        [InterviewQuestionsType.resume]: BackendPrompt;
        [InterviewQuestionsType.job]: BackendPrompt;
        [InterviewQuestionsType.specified]: BackendPrompt;
    };
    [BackendPromptType.jobContent]: {
        [JobContentPromptType.customizeResume]: BackendPrompt;
        [JobContentPromptType.createCoverLetter]: BackendPrompt;
        [JobContentPromptType.mockInterview]: BackendPrompt;
        [JobContentPromptType.recommendUpskilling]: BackendPrompt;
    };
    [BackendPromptType.systemPrompt]: {
        [SystemPromptType.chat]: BackendPrompt;
        [SystemPromptType.advancedInterview]: BackendPrompt;
        [SystemPromptType.interviewAnalysis]: BackendPrompt;
        [SystemPromptType.jobSummary]: BackendPrompt;
        [SystemPromptType.interviewQsPreamble]: BackendPrompt;
        [SystemPromptType.interviewQsMessage]: BackendPrompt;
    };
}

export enum SystemPromptType {
    chat = 'chat',
    advancedInterview = 'advancedInterview',
    interviewAnalysis = 'interviewAnalysis',
    jobSummary = 'jobSummary',
    interviewQsPreamble = 'interviewQsPreamble',
    interviewQsMessage = 'interviewQsMessage',
}

export const FetchAllPrompts = () => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const allPrompts = await PromptsService.fetchAllPrompts();
        if (allPrompts) {
            const allPromptStruct: AllPrompts = {
                [BackendPromptType.job]: {
                    [JobMatchType.resumeAndPreferences]: allPrompts.find(prompt => prompt.type === BackendPromptType.job && prompt.ref === JobMatchType.resumeAndPreferences) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.job },
                    [JobMatchType.specificRole]: allPrompts.find(prompt => prompt.type === BackendPromptType.job && prompt.ref === JobMatchType.specificRole) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.job },
                    [TopLevelJobMatchType.advice]: allPrompts.find(prompt => prompt.type === BackendPromptType.job && prompt.ref === TopLevelJobMatchType.advice) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.job },
                },
                [BackendPromptType.resume]: {
                    [ResumeFixType.improvements]: allPrompts.find(prompt => prompt.type === BackendPromptType.resume && prompt.ref === ResumeFixType.improvements) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.resume },
                    [ResumeFixType.bestPractices]: allPrompts.find(prompt => prompt.type === BackendPromptType.resume && prompt.ref === ResumeFixType.bestPractices) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.resume },
                    [ResumeFixType.savedJobs]: allPrompts.find(prompt => prompt.type === BackendPromptType.resume && prompt.ref === ResumeFixType.savedJobs) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.resume },
                    [ResumeFixType.specificRole]: allPrompts.find(prompt => prompt.type === BackendPromptType.resume && prompt.ref === ResumeFixType.specificRole) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.resume },
                },
                [BackendPromptType.coverLetter]: {
                    [CoverLetterType.general]: allPrompts.find(prompt => prompt.type === BackendPromptType.coverLetter && prompt.ref === CoverLetterType.general) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.coverLetter },
                    [CoverLetterType.savedJobs]: allPrompts.find(prompt => prompt.type === BackendPromptType.coverLetter && prompt.ref === CoverLetterType.savedJobs) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.coverLetter },
                    [CoverLetterType.specificRole]: allPrompts.find(prompt => prompt.type === BackendPromptType.coverLetter && prompt.ref === CoverLetterType.specificRole) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.coverLetter },
                },
                [BackendPromptType.upskill]: {
                    [UpskillingType.resumeAndPreferences]: allPrompts.find(prompt => prompt.type === BackendPromptType.upskill && prompt.ref === UpskillingType.resumeAndPreferences) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.upskill },
                    [UpskillingType.savedJobs]: allPrompts.find(prompt => prompt.type === BackendPromptType.upskill && prompt.ref === UpskillingType.savedJobs) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.upskill },
                    [UpskillingType.specificRole]: allPrompts.find(prompt => prompt.type === BackendPromptType.upskill && prompt.ref === UpskillingType.specificRole) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.upskill },
                },
                [BackendPromptType.interviewPrep]: {
                    [InterviewQuestionsType.resume]: allPrompts.find(prompt => prompt.type === BackendPromptType.interviewPrep && prompt.ref === InterviewQuestionsType.resume) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.interviewPrep },
                    [InterviewQuestionsType.job]: allPrompts.find(prompt => prompt.type === BackendPromptType.interviewPrep && prompt.ref === InterviewQuestionsType.job) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.interviewPrep },
                    [InterviewQuestionsType.specified]: allPrompts.find(prompt => prompt.type === BackendPromptType.interviewPrep && prompt.ref === InterviewQuestionsType.specified) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.interviewPrep },
                },
                [BackendPromptType.jobContent]: {
                    [JobContentPromptType.customizeResume]: allPrompts.find(prompt => prompt.type === BackendPromptType.jobContent && prompt.ref === JobContentPromptType.customizeResume) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.jobContent },
                    [JobContentPromptType.createCoverLetter]: allPrompts.find(prompt => prompt.type === BackendPromptType.jobContent && prompt.ref === JobContentPromptType.createCoverLetter) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.jobContent },
                    [JobContentPromptType.mockInterview]: allPrompts.find(prompt => prompt.type === BackendPromptType.jobContent && prompt.ref === JobContentPromptType.mockInterview) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.jobContent },
                    [JobContentPromptType.recommendUpskilling]: allPrompts.find(prompt => prompt.type === BackendPromptType.jobContent && prompt.ref === JobContentPromptType.recommendUpskilling) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.jobContent },
                },
                [BackendPromptType.systemPrompt]: {
                    [SystemPromptType.chat]: allPrompts.find(prompt => prompt.type === BackendPromptType.systemPrompt && prompt.ref === SystemPromptType.chat) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.systemPrompt },
                    [SystemPromptType.advancedInterview]: allPrompts.find(prompt => prompt.type === BackendPromptType.systemPrompt && prompt.ref === SystemPromptType.advancedInterview) ?? { id: 1, prompt: '', ref: '', type: BackendPromptType.systemPrompt },
                    [SystemPromptType.interviewAnalysis]: allPrompts.find(prompt => prompt.type === BackendPromptType.systemPrompt && prompt.ref === SystemPromptType.interviewAnalysis) ?? { id: 2, prompt: '', ref: '', type: BackendPromptType.systemPrompt },
                    [SystemPromptType.jobSummary]: allPrompts.find(prompt => prompt.type === BackendPromptType.systemPrompt && prompt.ref === SystemPromptType.jobSummary) ?? { id: 3, prompt: '', ref: '', type: BackendPromptType.systemPrompt },
                    [SystemPromptType.interviewQsPreamble]: allPrompts.find(prompt => prompt.type === BackendPromptType.systemPrompt && prompt.ref === SystemPromptType.interviewQsPreamble) ?? { id: 4, prompt: '', ref: '', type: BackendPromptType.systemPrompt },
                    [SystemPromptType.interviewQsMessage]: allPrompts.find(prompt => prompt.type === BackendPromptType.systemPrompt && prompt.ref === SystemPromptType.interviewQsMessage) ?? { id: 5, prompt: '', ref: '', type: BackendPromptType.systemPrompt },
                }
            }

            dispatch(setAllPrompts(allPromptStruct));
        }
    }
};

