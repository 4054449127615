import { useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { AppColors } from '../../theme/AppTheme';

interface AutocompleteFieldProps {
    value?: string;
    onPlaceSelected: (place: google.maps.places.PlaceResult | null) => void;
}

const AutocompleteField = (props: AutocompleteFieldProps) => {
    const { value, onPlaceSelected } = props;
    const theme = useTheme();
    const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if (inputRef.current) {
            autocompleteRef.current = new google.maps.places.Autocomplete(inputRef.current, {
                types: ['(cities)'],
                componentRestrictions: { country: ['ca', 'us'] },
            });

            autocompleteRef.current.addListener('place_changed', () => {
                if (autocompleteRef.current) {
                    const place = autocompleteRef.current.getPlace();
                    onPlaceSelected(place);
                }
            });
        }
    }, [onPlaceSelected]);

    const onFocused = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
        }
        setFocused(true);
    }

    const onBlurred = () => {
        setFocused(false);
    }

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            marginBottom: '15px',
            boxSizing: 'border-box',
        }}>
            <input
                id='autocomplete'
                autoComplete="off"
                ref={inputRef}
                value={focused ? undefined : value}
                placeholder="Enter preferred locations…"
                onFocus={onFocused}
                onBlur={onBlurred}
                style={{
                    boxSizing: 'border-box',
                    backgroundColor: AppColors.white,
                    borderRadius: '12px',
                    minHeight: '60px',
                    width: '100%',
                    paddingLeft: '18px',
                    border: `1.5px solid ${AppColors.grey.border}`,
                    paddingRight: '12px',
                    ...theme.typography.h5,
                    color: AppColors.black,
                }}
            />
        </div>
    );
};

export default AutocompleteField;