import { StartCoverLetterAction } from '../../redux/actions/CoverLetterActions';
import { SavedJobSelectedAction } from '../../redux/actions/InterviewActions';
import { StartInterviewQuestionsAction } from '../../redux/actions/InterviewQuestionsActions';
import { StartResumeOptimizationAction } from '../../redux/actions/SavedResumeActions';
import { StartUpskillingAction } from '../../redux/actions/UpskillingActions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import CoverLetterType from '../../shared/enums/CoverLetterType';
import InterviewQuestionsType from '../../shared/enums/InterviewQuestionsType';
import ResumeFixType from '../../shared/enums/ResumeType';
import UpskillingType from '../../shared/enums/UpskillingType';
import Job from '../../shared/models/Job';
import DesktopJobCard from './DesktopJobCard';
import { marginForIndex, widthForIndex } from './MessageCell';
import MobileJobCard from './MobileJobCard';

export enum SavedJobSelectionType {
    interview,
    resume,
    coverLetter,
    interviewQuestions,
    upskilling,
}

interface SavedJobSelectionProps {
    type: SavedJobSelectionType;
}

const SavedJobSelection = (props: SavedJobSelectionProps) => {
    const isMobile = useAppSelector(state => state.appState.isMobile);
    const savedJobs = useAppSelector(state => state.savedJobsState.jobsArray ?? []);

    const dispatch = useAppDispatch();

    const onJobSelected = (job: Job) => {
        switch (props.type) {
            case SavedJobSelectionType.interview:
                dispatch(SavedJobSelectedAction(job));
                break;
            case SavedJobSelectionType.resume:
                dispatch(StartResumeOptimizationAction(ResumeFixType.savedJobs, job));
                break;
            case SavedJobSelectionType.coverLetter:
                dispatch(StartCoverLetterAction(CoverLetterType.savedJobs, job));
                break;
            case SavedJobSelectionType.interviewQuestions:
                dispatch(StartInterviewQuestionsAction(InterviewQuestionsType.job, job));
                break;
            case SavedJobSelectionType.upskilling:
                dispatch(StartUpskillingAction(UpskillingType.savedJobs, job));
                break;
        }
    }

    const HeaderRow = <div style={{ display: 'flex', paddingLeft: '30px', paddingRight: '30px', marginBottom: '20px', marginTop: '30px', alignItems: 'center', }}>
        <HeaderCell cellIndex={0} title='' />
        <HeaderCell cellIndex={1} title='Job title' />
        <HeaderCell cellIndex={2} title='Location' />
    </div>;
    // Build the job cards
    const JobCards = savedJobs.map((job, index) => {
        return isMobile
            ? <MobileJobCard minHeight='76px' key={index} job={job} index={index} onClick={() => onJobSelected(job)} />
            : <DesktopJobCard minHeight='100px' key={index} job={job} index={index} onClick={() => onJobSelected(job)} />
    });

    return (
        <div style={{ width: '100%' }}>
            {!isMobile ? HeaderRow : <div style={{ minHeight: '25px', width: '100%' }} />}
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, maxHeight: isMobile ? '400px' : '520px', overflowY: 'auto', width: '100%' }}>
                {JobCards}
            </div>
        </div>
    );
}

const HeaderCell = (props: { title: string, cellIndex: number }) => {
    const { title, cellIndex } = props;
    return (<div style={{ textAlign: 'left', whiteSpace: 'nowrap', minWidth: widthForIndex(cellIndex), maxWidth: widthForIndex(cellIndex), marginLeft: marginForIndex(cellIndex) }} key={cellIndex}>
        {title}
    </div>);
}

export default SavedJobSelection