import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bars } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import IncomeOptionInput from "../../mainTabs/profile/IncomeOptionInput";
import { workEnvironments as workEnvironmentOptions } from "../../mainTabs/profile/selections/SingleSelectValues";
import SingleSelectOptionInput from "../../mainTabs/profile/SingleSelectOptionInput";
import SkillsArray from "../../mainTabs/profile/SkillsArray";
import { useAppSelector } from "../../redux/hooks";
import { setUser } from "../../redux/reducers/userReducer";
import UserService from "../../services/userService";
import CloseButton from "../../shared/components/CloseButton";
import FullScreenModalDiv from "../../shared/components/FullScreenModalDiv";
import PrimaryButton from "../../shared/components/PrimaryButton";
import { AppColors } from "../../theme/AppTheme";
import AutocompleteField from "../components/AutocompleteField";
import DesiredIncome from "../models/DesiredIncome";
import UserInfo from "../models/UserInfo";


const StyledContainer = styled('div')((props: { isMobile: boolean }) => ({
    position: 'relative',
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: props.isMobile ? '100vw' : '70%',
    height: props.isMobile ? '100%' : undefined,
    maxWidth: props.isMobile ? '100vw' : '650px',
    maxHeight: props.isMobile ? '100%' : '760px',
    backgroundColor: AppColors.white,
    padding: props.isMobile ? '0px 0px' : undefined,
}));

const StyledScrollingContainer = styled('div')((props: { isMobile: boolean }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: props.isMobile ? 'calc(100% - 40px)' : '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: AppColors.white,
    padding: props.isMobile ? '68px 24px 0px 24px' : '54px 100px 64px',
    paddingBottom: '64px',
    flexGrow: 1,
}));

const StyledButtons = styled('div')((props: { isMobile: boolean }) => ({
    padding: '12px',
    borderTop: `1px solid ${AppColors.grey.border}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '22px',
    width: '100%',
}));

interface JobInfoModalProps {
    initialInfo: UserInfo;
    onboarding?: boolean;
    onDonePressed: () => void;
    onBackPressed: () => void;
}

const JobInfoModal = (props: JobInfoModalProps) => {
    const { initialInfo, onboarding, onDonePressed, onBackPressed } = props;

    const [locations, setLocations] = useState(initialInfo.preferredLocations ?? []);
    const [desiredPositions, setDesiredPositions] = useState<string[]>(initialInfo.desiredPositions ?? []);
    const [desiredIncome, setDesiredIncome] = useState(initialInfo.desiredIncome ?? new DesiredIncome(''));
    const [workEnvironments, setWorkEnvironments] = useState(initialInfo.preferredWorkEnvironments ?? []);

    const [_, setScrollContainerReady] = useState(false);

    const [saving, setSaving] = useState(false);
    const { t } = useTranslation();
    const loading = useAppSelector((s) => s.userState.authIsBusy);
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (scrollContainerRef.current) {
            setScrollContainerReady(true);
        }
    }, [scrollContainerRef]);

    const onSavePressed = async () => {
        setSaving(true);
        try {
            const newUserInfo: UserInfo = {
                desiredPositions: desiredPositions,
                desiredIncome: desiredIncome,
                preferredWorkEnvironments: workEnvironments,
                preferredLocations: locations,
            }
            const user = await UserService.updateUserInfo(newUserInfo)
            dispatch(setUser(user));
            onDonePressed();
        } catch (e) {
            console.error(e);
        } finally {
            setSaving(false);
        }
    }

    const getCityFromPlace = (place: google.maps.places.PlaceResult | null) => {
        if (!place) return null;
        const addressComponents = place.address_components;
        if (!addressComponents) return null;

        for (let component of addressComponents) {
            const types = component.types;
            if (types.includes('locality')) {
                return component.long_name; // City name
            }
            if (types.includes('administrative_area_level_1') && !types.includes('locality')) {
                return component.long_name; // In case "locality" is not available
            }
        }

        return null; // City not found in the place result
    };

    const addLocation = (place: google.maps.places.PlaceResult | null) => {
        const city = getCityFromPlace(place);
        if (city) {
            if (locations.includes(city)) return;
            setLocations((current) => [...(current ?? []), city]);
        }
    }

    const removeLocation = (city: string) => {
        setLocations((current) => current.filter((c) => c !== city));
    }

    return (
        <FullScreenModalDiv>
            <StyledContainer isMobile={isMobile}>
                <CloseButton onClick={onBackPressed} back={onboarding} />
                {!onboarding && <CloseButton onClick={onBackPressed} back={false} />}
                <div style={{ zIndex: 1, position: 'absolute', top: 0, left: 0, right: 0, minHeight: '60px', height: '60px', backgroundColor: AppColors.white }} />
                <StyledScrollingContainer isMobile={isMobile} ref={scrollContainerRef}>
                    <Typography variant='h2' style={{ marginBottom: '21px' }}>
                        Add Your Job Preferences
                    </Typography>
                    <Typography variant='h5' style={{
                        textAlign: 'center',
                        marginBottom: '40px',
                        color: AppColors.grey.darkish
                    }}>
                        Adding preferences will help match you with better jobs.
                    </Typography>
                    <IncomeOptionInput selectedValue={desiredIncome} scrollContainer={scrollContainerRef?.current} valueUpdated={(di) => setDesiredIncome(di)} />
                    {/* <SingleSelectOptionInput multiSelect scrollContainer={scrollContainerRef?.current} selectedValues={desiredIncome ?? []} possibleValues={incomeRanges} title="Desired Income" placeholder="Select income range" valueUpdated={(di) => setDesiredIncome(di)} /> */}
                    <Typography variant='h4' style={{ textAlign: 'left', paddingBottom: '15px', width: '100%' }}>
                        {"Preferred Location(s)"}
                    </Typography>
                    <AutocompleteField value={''} onPlaceSelected={addLocation} />
                    <SkillsArray greyPills={true} allSelected skills={locations} selectedSkills={locations} emptyStateText="" onRemove={removeLocation} />
                    <span style={{ minHeight: '20px' }} />
                    <SingleSelectOptionInput multiSelect scrollContainer={scrollContainerRef?.current} selectedValues={workEnvironments ?? []} possibleValues={workEnvironmentOptions} title="Preferred Work Environments" placeholder="Select preferred work environments" valueUpdated={(newEnvs) => setWorkEnvironments(newEnvs)} />
                </StyledScrollingContainer>
                <StyledButtons isMobile={isMobile}>
                    <PrimaryButton title={onboarding ? 'DONE' : 'SAVE'} height={'48px'} paddingHorizontal="20%" disabled={saving || loading} onClick={onSavePressed} />
                </StyledButtons>
                {loading || saving && <div style={{ zIndex: 999999, background: 'rgba(0,0,0,0.3)', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Bars
                        height="30"
                        width="30"
                        color={AppColors.pink.dark}
                        ariaLabel="bars-loading"
                        visible={true}
                    />
                </div>}
            </StyledContainer>
        </FullScreenModalDiv>
    )
}

export default JobInfoModal;