import styled from '@emotion/styled';
import { Check } from '@mui/icons-material';
import { Input, Modal, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import CloseIcon from '../../assets/icons/close_icon';
import CoverLetterIconSmall from '../../assets/icons/cover_letter_icon_small';
import ResumeIconSmall from '../../assets/icons/resume_icon_small';
import RewriteIcon from '../../assets/icons/rewrite_icon';
import SavedIcon from '../../assets/icons/save_icon_selected';
import SaveIcon from '../../assets/icons/save_icon_unselected';
import SavedResume, { SavedResumeType } from '../../mainTabs/savedResumes/model/SavedResume';
import { ClearAllFocusedAction } from '../../redux/actions/GlobalActions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addSavedResume, removeSavedResume, setSelectedResume, toggleSavedResumes, updateSavedResume } from '../../redux/reducers/savedResumeReducer';
import { setUser } from '../../redux/reducers/userReducer';
import ResumeService from '../../services/resumeService';
import UserService from '../../services/userService';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import ConfirmationModal from '../../shared/components/ConfirmationModal';
import LexicalEditor from '../../shared/components/Lexical/LexicalEditor';
import NotificationBar, { NotificationType } from '../../shared/components/NotificationBar';
import OutlinedButton from '../../shared/components/OutlinedButton';
import PrimaryButton from '../../shared/components/PrimaryButton';
import { createDocx } from '../../shared/utils/MarkdownUtils';
import resumeNameCache from '../../shared/utils/ResumeNameCache';
import zIndicies from '../../shared/utils/zIndexConstants';
import { AppColors, sharpTransition } from '../../theme/AppTheme';
import DownloadFileModal from '../components/DownloadFileModal';
import DocumentContentType from '../enums/DocumentContentType';
import { setSelectedTab } from '../../redux/reducers/appReducer';
import { MainTab, MainTabType } from '../../navigation/classes/MainTab';



const DetailsPage = styled('div')((props: { position: number, fixed: boolean, isMobile: boolean }) => ({
    position: props.fixed ? 'absolute' : 'relative',
    right: props.fixed ? -props.position : undefined,
    top: props.fixed ? 0 : undefined,
    bottom: props.fixed ? 0 : undefined,
    backgroundColor: AppColors.white,
    transition: `right 0.2s ${sharpTransition}`,
    width: props.isMobile ? props.fixed ? '98%' : '100%' : (props.fixed ? 'clamp(500px, 58vw, 1200px)' : '100%'),
    overflow: 'hidden',
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
}));

const ScrollableContainer = styled('div')((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingLeft: props.isMobile ? '16px' : 'clamp(24px, 6vw, 145px)',
    paddingRight: props.isMobile ? '16px' : 'clamp(24px, 6vw, 145px)',
    paddingTop: props.isMobile ? '30px' : '54px',
    alignItems: 'flex-start',
    width: props.isMobile ? 'calc(100% - 32px)' : '100%',
    height: props.isMobile ? 'calc(100% - 50px)' : '100%',
    maxHeight: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingBottom: '50px',
    zIndex: zIndicies.jobFlyout,
    textAlign: 'left',
}));

const StyledButtons = styled('div')((props: { isMobile: boolean }) => ({
    padding: '10px',
    borderTop: `1px solid ${AppColors.grey.border}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '15px',
    width: props.isMobile ? 'calc(100% - 20px)' : '100%',
    backgroundColor: AppColors.white,
    marginBottom: 'env(safe-area-inset-bottom)',
}));

const StyledTopBar = styled('div')((props: { isMobile: boolean }) => ({
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: props.isMobile ? '0px' : '34px',
    paddingBottom: props.isMobile ? '0px' : '20px',
    paddingLeft: props.isMobile ? 0 : '34px',
    paddingRight: props.isMobile ? 0 : '34px',
    backgroundColor: AppColors.white,
    zIndex: props.isMobile ? zIndicies.flyoutPanelMobile : zIndicies.flyoutPanelDesktop,
}));

const StyledTitleRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
}));

const RightButtons = styled('div')((props: { isMobile: boolean }) => ({
    position: props.isMobile ? 'fixed' : undefined,
    right: '16px',
    top: '14px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '20px',
}));

const Divider = () => {
    return <div style={{ width: '100%', minHeight: '1.5px', backgroundColor: AppColors.separator }} />
}

interface ResumeContentPageProps {
    savedResume?: SavedResume;
    resumeString?: string;
    title?: string;
    type: SavedResumeType
    animated: boolean;
    messageIndex: number;
    onClose?: () => void;
}

const ResumeContentPage = (props: ResumeContentPageProps) => {
    const { resumeString: resume, type, title, onClose, animated, savedResume, messageIndex } = props;
    const user = useAppSelector(state => state.userState.currentUser);
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const focusedResume = useAppSelector(state => state.documentState.focusedResume);
    const currentChat = useAppSelector(state => state.appState.activeChat);
    const [position, setPosition] = useState(animated ? 800 : 0);
    const [resumeConfirmationOpen, setResumeConfirmationOpen] = useState(false);
    const [resumeSetOpen, setResumeSetOpen] = useState(false);
    const [downloadOpen, setDownloadOpen] = useState(false);
    const savedResumes = useAppSelector(state => (savedResume?.type ?? props.type) === SavedResumeType.resume ? state.savedResumesState.resumesArray : state.savedResumesState.coverLettersArray);
    const [isSaved, setIsSaved] = useState(savedResumes.some(i => i.id === savedResume?.id));
    const savedResumeToggle = useAppSelector(state => state.savedResumesState.savedResumeToggle);
    const [currentResume, setCurrentResume] = useState<SavedResume | undefined>(savedResume);
    const [isEditing, setIsEditing] = useState(false);
    const [resumeNotifText, setResumeNotifText] = useState('');
    const [currentTitle, setCurrentTitle] = useState(savedResume?.title ?? focusedResume?.title ?? resumeNameCache.getResumeName(currentChat?.sessionId, messageIndex) ?? 'My Resume');
    const [currentResumeBody, setCurrentResumeBody] = useState(currentResume?.content ?? focusedResume?.content ?? resume);
    const theme = useTheme();

    const dispatch = useAppDispatch();

    useEffect(() => {
        setPosition(0);
    }, []);

    useEffect(() => {
        if (!currentResume) return;
        setIsSaved(savedResumes.some(i => i.id === currentResume?.id));
    }, [currentResume, savedResumeToggle]);

    useEffect(() => {
        setCurrentResume(savedResume);
        setCurrentTitle(savedResume?.title ?? focusedResume?.title ?? resumeNameCache.getResumeName(currentChat?.sessionId, messageIndex) ?? 'My Resume');
        setCurrentResumeBody(savedResume?.content ?? focusedResume?.content ?? resume);
    }, [savedResume]);

    const close = () => {
        if (onClose)
            onClose();
        if (animated) {
            setPosition(800);
            setTimeout(() => {
                dispatch(ClearAllFocusedAction());
            }, 200);
        } else {
            dispatch(ClearAllFocusedAction());
        }
    }

    const resumeFilename = (): string => {
        const usersName = user?.firstName ? `${user?.firstName} ` : '';
        return `${usersName}${type === SavedResumeType.resume ? 'Resume' : 'CoverLetter'}-${(new Date()).toISOString()}`;
    }

    const setAsResumePressed = () => {
        setResumeConfirmationOpen(true);
    }

    const setAsResume = async () => {
        const resumeContent = currentResumeBody;
        if (!resumeContent) return;
        setResumeConfirmationOpen(false);
        const filename = resumeFilename();
        const resumeFile = await createDocx(resumeContent, filename);
        const updatedUser = await UserService.uploadResume(resumeFile);
        dispatch(setUser(updatedUser));
        setResumeNotifText('Resume saved to profile');
        setResumeSetOpen(true);
    }

    const getResumeTitle = (): string => {
        let title = currentResume?.title
        if (title && title.trim() !== '') return title;
        title = focusedResume?.title;
        if (title && title.trim() !== '') return title;
        title = resumeNameCache.getResumeName(currentChat?.sessionId, messageIndex);
        if (title && title.trim() !== '') return title;
        return type === SavedResumeType.resume ? 'My Resume' : 'My Cover Letter';
    }

    const onBookmarkToggled = async () => {
        if (isSaved) {
            if (!currentResume) return;
            await ResumeService.unsaveResume(currentResume);
            dispatch(removeSavedResume(currentResume));
        } else {
            const content = currentResumeBody;
            const resumeTitle = getResumeTitle();
            if (!content) return;
            const newResume = await ResumeService.saveResume(resumeTitle, content, type);
            if (!newResume) return;
            dispatch(addSavedResume(newResume));
            setCurrentResume(newResume);
            setSelectedResume(newResume);
            toggleSavedResumes();
            setResumeNotifText(type === SavedResumeType.resume ? 'Resume added to your saved resumes' : 'Cover letter added to your saved cover letters');
            setResumeSetOpen(true);
        }
        setIsSaved(!isSaved);
    }

    const onEditPressed = async () => {
        if (isEditing) {
            // Save new title
            const resumeId = currentResume?.id;
            if (resumeId) {
                const updatedResume = await ResumeService.updateResume(resumeId, currentTitle, currentResumeBody ?? '');
                if (!updatedResume) return;
                setCurrentResume(updatedResume);
                dispatch(updateSavedResume(updatedResume));
            }
            setIsEditing(false);
        } else {
            setIsEditing(true);
        }
    }

    const onTitleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentTitle(event.target.value);
    }

    const onShowSaved = () => {
        if (type === SavedResumeType.resume) {
            dispatch(setSelectedTab(MainTabType.savedResumes));
        } else {
            dispatch(setSelectedTab(MainTabType.savedCoverLetters));
        }
    }

    return (
        <DetailsPage position={position} fixed={animated} isMobile={isMobile}>
            <StyledTopBar isMobile={isMobile}>
                {animated ? <ClickableOpacityDiv style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: AppColors.white,
                    zIndex: zIndicies.jobCloseButton,
                    paddingLeft: isMobile ? '16px' : undefined,
                }}
                    onClick={close}>
                    <CloseIcon />
                </ClickableOpacityDiv> : <>{isMobile ? '' : ' '}</>}
                {isMobile && animated && <div style={{ display: 'flex', flexDirection: 'row', gap: '12px', paddingRight: '16px', paddingTop: '14px', paddingBottom: '14px' }}>
                    <ClickableOpacityDiv style={{ paddingLeft: '18px', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30px' }} onClick={onEditPressed}>
                        <div style={{ height: '30px', display: 'flex', alignItems: 'center' }} >
                            {isEditing ? <Check htmlColor={AppColors.black} /> : <RewriteIcon color={AppColors.black} />}
                        </div>
                        <Typography variant='subtitle1' style={{ marginLeft: '8px', color: AppColors.grey.dark, height: '30px', display: 'flex', alignItems: 'center' }}>
                            {isEditing ? 'Save' : 'Edit'}
                        </Typography>
                    </ClickableOpacityDiv>
                    {!isEditing && <ClickableOpacityDiv
                        id={'resume-save-btn'}
                        onClick={onBookmarkToggled}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {isSaved ? <SavedIcon /> : <SaveIcon />}
                    </ClickableOpacityDiv>}
                </div>}
                {(!isMobile || (isMobile && !animated)) && <RightButtons isMobile={isMobile}>
                    <ClickableOpacityDiv style={{ paddingLeft: '18px', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30px' }} onClick={onEditPressed}>
                        <div style={{ height: '30px', display: 'flex', alignItems: 'center' }} >
                            {isEditing ? <Check htmlColor={AppColors.black} /> : <RewriteIcon color={AppColors.black} />}
                        </div>
                        <Typography variant='subtitle1' style={{ marginLeft: '8px', color: AppColors.grey.dark, height: '30px', display: 'flex', alignItems: 'center' }}>
                            {isEditing ? 'Save' : 'Edit'}
                        </Typography>
                    </ClickableOpacityDiv>
                    {!isEditing && <ClickableOpacityDiv
                        id={'resume-save-btn'}
                        onClick={onBookmarkToggled}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {isSaved ? <SavedIcon /> : <SaveIcon />}
                    </ClickableOpacityDiv>
                    }
                </RightButtons>
                }
            </StyledTopBar>
            <ScrollableContainer isMobile={isMobile}>
                {!savedResume?.title ? <>
                    {isEditing &&
                        <Typography variant='h4'>
                            Edit {type === SavedResumeType.resume ? 'Resume' : 'Cover Letter'} Title
                        </Typography>
                    }
                    <StyledTitleRow>
                        <Typography variant='h2' style={{ width: isEditing ? '100%' : undefined, color: AppColors.black, fontSize: isMobile ? '18px' : '24px', lineHeight: isMobile ? '30px' : 'auto', marginBottom: '16px' }}>
                            {isEditing
                                ? <Input
                                    autoFocus
                                    id={'edit-title-input'}
                                    value={currentTitle}
                                    onChange={onTitleChanged}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            onEditPressed();
                                        }
                                    }}
                                    placeholder='Enter title'
                                    style={{ ...theme.typography.h2, width: '100%', fontSize: isMobile ? '18px' : '24px' }}
                                />
                                : currentTitle}
                        </Typography>
                    </StyledTitleRow>
                </>
                    :
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        {isEditing &&
                            <Typography variant='h4' style={{ paddingBottom: '18px' }}>
                                Edit Title
                            </Typography>
                        }
                        <StyledTitleRow>
                            <Typography variant='h2' style={{ width: isEditing ? '100%' : undefined, color: AppColors.black, fontSize: isMobile ? '18px' : '24px', lineHeight: isMobile ? '30px' : 'auto', marginBottom: '16px' }}>
                                {isEditing
                                    ? <Input
                                        autoFocus
                                        id={'edit-title-input'}
                                        value={currentTitle}
                                        onChange={onTitleChanged}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                onEditPressed();
                                            }
                                        }}
                                        placeholder='Enter title'
                                        style={{ ...theme.typography.h2, width: '100%', fontSize: isMobile ? '18px' : '24px' }}
                                    />
                                    : currentTitle}
                            </Typography>
                        </StyledTitleRow>
                    </div>
                }
                <Divider />
                <div style={{ minHeight: '30px' }} />
                <Typography
                    variant='h5'
                    sx={{
                        textAlign: 'left',
                        color: AppColors.black,
                        userSelect: 'text',
                        webkitUserSelect: 'text',
                        maxWidth: '100%',
                    }}
                >
                    {isEditing &&
                        <Typography variant='h4' style={{ paddingTop: "22px", paddingBottom: "8px" }}>
                            Edit {type === SavedResumeType.resume ? 'Resume' : 'Cover Letter'}
                        </Typography>
                    }
                    {isEditing ? <LexicalEditor
                        initialValue={currentResumeBody}
                        newContent={(content) => {
                            setCurrentResumeBody(content)
                        }}
                    />
                        : <ReactMarkdown>
                            {currentResumeBody}
                        </ReactMarkdown>
                    }
                </Typography>
            </ScrollableContainer>
            {/* TODO: Resize the buttons, try and get add to profile on one line */}
            {!isEditing && <StyledButtons isMobile={isMobile}>
                <PrimaryButton title={'Download'} disabled={false} onClick={() => setDownloadOpen(true)} height={'48px'} style={{ width: isMobile ? '130px' : '160px' }} />
                <OutlinedButton title={isSaved ? 'Unsave' : 'Save'} disabled={false} onClick={() => onBookmarkToggled()} height={'48px'} style={{ width: isMobile ? '120px' : '150px' }} />
                {type === SavedResumeType.resume && <OutlinedButton title={'Add to profile'} disabled={false} onClick={setAsResumePressed} height={'48px'} style={{ width: isMobile ? '150px' : '130px' }} />}
            </StyledButtons>
            }
            <Modal
                sx={{ zIndex: zIndicies.modal }}
                open={resumeConfirmationOpen}>
                <ConfirmationModal title="Are you sure you want to set this as the resume in your profile?" subtitle={<>Your current resume will be replaced</>} onCancel={() => { setResumeConfirmationOpen(false) }} onConfirm={setAsResume} confirmText="Yes" />
            </Modal>
            {resumeSetOpen && <NotificationBar
                type={NotificationType.message}
                marginTop={isMobile ? 55 : 84}
                onClose={() => setResumeSetOpen(false)}
                shouldClose={false}
                message={resumeNotifText}
                onAction={onShowSaved}
                icon={type === SavedResumeType.resume ? <ResumeIconSmall color={AppColors.white} /> : <CoverLetterIconSmall color={AppColors.white} />}
            />}
            <Modal
                open={downloadOpen}
                onClose={() => setDownloadOpen(false)}
                sx={{ zIndex: 999999, }}
            >
                <DownloadFileModal type={DocumentContentType.resume} content={currentResume?.content ?? resume ?? ''} onDownloaded={() => setDownloadOpen(false)} />
            </Modal>
        </DetailsPage>
    )
}

export default ResumeContentPage;