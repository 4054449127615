import UserTypeManager from "./UserCodeManager";

export const getAuthCode = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    if (code) {
        console.log('found and applied code:', code);
        UserTypeManager.setTypeFromCode(code);
    } else {
        UserTypeManager.clearUserType();
    }
};
