import { Typography } from "@mui/material";
import { useLayoutEffect, useRef, useState } from "react";
import DesiredIncome from "../../profile/models/DesiredIncome";
import PPTextInput from "../../shared/components/PPTextInput";
import IncomeOption from "./IncomeOption";

interface IncomeOptionProps {
    selectedValue: DesiredIncome;
    scrollContainer: HTMLElement | null;
    valueUpdated: (value: DesiredIncome) => void;
}

const IncomeOptionInput = (props: IncomeOptionProps) => {
    const { selectedValue, scrollContainer, valueUpdated } = props;

    const [showSearch, setShowSearch] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);
    const buttonRef = useRef<HTMLInputElement>(null);

    useLayoutEffect(() => {
        // Put button on top of the input
        if (inputRef.current && buttonRef.current && scrollContainer) {
            const inputRect = inputRef.current.getBoundingClientRect();
            const scrollContainerRect = scrollContainer.getBoundingClientRect();
            buttonRef.current.style.position = 'absolute';
            buttonRef.current.style.top = `${inputRect.top + scrollContainer.scrollTop - scrollContainerRect.top}px`;
            buttonRef.current.style.left = `${inputRect.left - scrollContainerRect.left}px`;
            buttonRef.current.style.width = `${inputRect.width}px`;
            buttonRef.current.style.height = `${inputRect.height}px`;
            buttonRef.current.style.zIndex = '1';
        }
    }, [inputRef, buttonRef, scrollContainer]);

    const onSearchFocused = () => {
        setShowSearch(true);
    }

    const onSearchCompleted = () => {
        setShowSearch(false);
    }

    const onHourlyChanged = (hourly: string) => {
        const newHourly = hourly.length === 0 ? undefined : parseFloat(hourly);
        const newDesiredIncome = DesiredIncome.fromValues(newHourly, selectedValue.salaryBottom, selectedValue.salaryTop);
        valueUpdated(newDesiredIncome);
    }

    const onSalaryChanged = (salaryBottom: number, salaryTop: number) => {
        const newDesiredIncome = DesiredIncome.fromValues(selectedValue.hourlyRate, salaryBottom, salaryTop);
        valueUpdated(newDesiredIncome);
    }

    return (
        <>
            {showSearch &&
                <IncomeOption
                    value={selectedValue}
                    onHourlyChanged={onHourlyChanged}
                    onSalaryChanged={onSalaryChanged}
                    scrollContainer={scrollContainer}
                    onDone={onSearchCompleted}
                    anchorElement={inputRef.current}
                />}
            <Typography variant='h4' style={{ width: '100%', textAlign: 'left', paddingBottom: '16px' }}>
                Desired Income
            </Typography>
            {/* <div ref={buttonRef} style={{ cursor: 'pointer' }} onClick={onSearchFocused} /> */}
            <PPTextInput id={'income-search'}
                reference={inputRef}
                value={selectedValue?.displayString ?? ''}
                onValueChanged={() => { }}
                bottomPadding="30px"
                onFocused={() => {
                    onSearchFocused();
                    inputRef.current?.blur();
                }}
                placeholder={'Select desired income'}
            />
        </>
    )
}

export default IncomeOptionInput;