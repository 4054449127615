import styled from "@emotion/styled";
import { InterviewModeSelectedAction } from "../../redux/actions/InterviewActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import SuggestionRow from "../../shared/components/SuggestionRow";
import { InterviewMode, textForInterviewMode } from "../../shared/enums/InterviewType";

const StyledRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    maxWidth: '100%',
    gap: '15px',
}));

const InterviewModeSelection = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(store => store.userState.currentUser);

    const onInterviewModeSelected = (interviewMode: InterviewMode) => {
        dispatch(InterviewModeSelectedAction(interviewMode));
    }

    return (
        <StyledRow>
            {Object.values(InterviewMode).map((type, index) => {
                if (type === InterviewMode.conversation && (!user || !user.admin)) return undefined;
                return <SuggestionRow key={index} title={textForInterviewMode(type)} onClick={() => onInterviewModeSelected(type)} />
            })}
        </StyledRow>
    )
}

export default InterviewModeSelection