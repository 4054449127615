import IUser from "../../shared/interfaces/IUser";
import EditableInfo, { EditableObject } from "./EditableValue";

interface PersonalInfo extends EditableObject {
    'First Name'?: EditableInfo;
    'Last Name'?: EditableInfo;
    'Address'?: EditableInfo;
    'Phone'?: EditableInfo;
    'Email'?: EditableInfo;
}

export const userInfoFromPersonalInfo = (personalInfo: PersonalInfo): Partial<IUser> => {
    return {
        firstName: personalInfo['First Name']?.value,
        lastName: personalInfo['Last Name']?.value,
        address: personalInfo['Address']?.value,
        phone: personalInfo['Phone']?.value,
        linkedInUrl: personalInfo['LinkedIn']?.value,
    };
}

export default PersonalInfo;
