import styled from '@emotion/styled';
import { Checkbox, Typography } from '@mui/material';
import { getAuth } from "firebase/auth";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Bars } from 'react-loader-spinner';
import { Strings } from '../i18n';
import { CreateUserAction, UpdateUserAction } from '../redux/actions/UserActions';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import CloseButton from '../shared/components/CloseButton';
import PPTextInput from '../shared/components/PPTextInput';
import PrimaryButton from '../shared/components/PrimaryButton';
import { AppColors } from '../theme/AppTheme';
import EmailInput from './components/EmailInput';
import PasswordInput from './components/PasswordInput';
import { isValidEmail } from './helpers/EmailHelper';
import UserTypeManager from '../shared/utils/UserCodeManager';

const StyledContainer = styled('div')((props: { isMobile: boolean }) => ({
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: props.isMobile ? '100%' : '70%',
    height: props.isMobile ? '100%' : '90%',
    maxWidth: props.isMobile ? '100%' : '650px',
    maxHeight: props.isMobile ? '100%' : '760px',
    backgroundColor: AppColors.white,
}));

const StyledScrollingContainer = styled('div')((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: props.isMobile ? 'calc(100% - 48px)' : '100%',
    height: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    backgroundColor: AppColors.white,
    padding: props.isMobile ? '44px 24px' : '54px 100px 64px',
}));

const StyledButtons = styled('div')((props: { isMobile: boolean }) => ({
    padding: '12px 100px',
    borderTop: `1px solid ${AppColors.grey.border}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '22px',
    width: props.isMobile ? 'calc(100%)' : '100%',
}));

interface SignUpInfoScreenProps {
    initialEmail: string;
    switchToSignUpPressed: () => void;
}

const SignUpInfoScreen = (props: SignUpInfoScreenProps) => {
    const { switchToSignUpPressed, initialEmail } = props;

    const user = useAppSelector((s) => s.userState.currentUser);

    const [name, setName] = useState(user?.name || '');
    const [email, setEmail] = useState(user?.email || initialEmail);
    const [password, setPassword] = useState('');
    const [code, setCode] = useState('');

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [codeError, setCodeError] = useState('');

    const [marketingChecked, setMarketingChecked] = useState(true);
    const [codeAlreadyApplied, setCodeAlreadyApplied] = useState<boolean>(!!(UserTypeManager.getUserType()));

    const { t } = useTranslation();
    const auth = getAuth();
    const dispatch = useAppDispatch();
    const loading = useAppSelector((s) => s.userState.authIsBusy);
    const error = useAppSelector((s) => s.userState.authError);
    const isMobile = useAppSelector((s) => s.appState.isMobile);


    useEffect(() => {
        setCodeAlreadyApplied(!!(UserTypeManager.getUserType()));
    }, []);

    const onEmailChanged = (newEmail: string) => {
        setEmailError('');
        setEmail(newEmail);
    }

    const onPasswordChanged = (newPassword: string) => {
        setPasswordError('');
        setPassword(newPassword);
    }

    const onNameChanged = (newName: string) => {
        setNameError('');
        setName(newName);
    }

    const onSignUpPressed = async () => {
        setEmailError('');
        setPasswordError('');
        setNameError('');
        setCodeError('');

        if (!emailIsValid() || !passwordIsValid() || !nameIsValid() || !codeIsValid()) {
            console.log('invalid something');
            return;
        }

        const userType = UserTypeManager.getUserType();
        if (process.env.REACT_APP_NEEDS_CODE && !userType) return;

        if (!!user) {
            console.log('updating user');
            if (!emailIsValid() || !nameIsValid()) {
                console.log('invalid email or name');
                return;
            }
            dispatch(UpdateUserAction(name, marketingChecked, userType));
            return;
        }

        console.log('creating user');
        dispatch(CreateUserAction(auth, email, password, name, marketingChecked, userType));
    }

    const emailIsValid = (): boolean => {
        if (email === '') {
            setEmailError('Email is required');
            return false;
        } else if (!isValidEmail(email)) {
            setEmailError('Please enter a valid email');
            return false;
        }
        return true;
    }

    const passwordIsValid = (): boolean => {
        if (!!user) return true;
        if (password === '') {
            setPasswordError('Password is required');
            return false;
        } else if (password.length < 6) {
            setPasswordError('Password must be at least 6 characters');
            return false;
        }
        return true;
    }

    const nameIsValid = (): boolean => {
        if (name === '') {
            setNameError('Name is required');
            return false;
        }
        return true;
    }

    const codeIsValid = (): boolean => {
        if (codeAlreadyApplied || process.env.REACT_APP_NEEDS_CODE !== 'true') {
            return true;
        }
        if (!code || code === '') {
            setCodeError('A code is required');
            return false;
        }
        const codeApplied = UserTypeManager.setTypeFromCode(code);
        if (!codeApplied) {
            setCodeError('Invalid code');
            return false;
        }

        return true;
    }

    const onBackPressed = () => {
        switchToSignUpPressed();
    }

    const buttonEnabled = (): boolean => {
        if (!!user) {
            return name.length > 0;
        }
        return email.length > 0 && password.length > 0 && name.length > 0;
    }

    return (
        <StyledContainer isMobile={isMobile}>
            {!!user && <CloseButton onClick={onBackPressed} />}
            <StyledScrollingContainer isMobile={isMobile}>
                <Typography variant='h2' style={{ marginBottom: '21px' }}>
                    {t(Strings.createAccountTitle)}
                </Typography>
                <Typography variant='h5' style={{
                    textAlign: 'center',
                    marginBottom: '40px',
                    color: AppColors.grey.darkish
                }}>
                    {t(Strings.createAccountSubtitle)}
                </Typography>
                {(!codeAlreadyApplied && process.env.REACT_APP_NEEDS_CODE === 'true') && <PPTextInput id='code-input' value={code} onValueChanged={setCode} title={'Pilot Code'} placeholder='Enter code' error={codeError} />}
                <PPTextInput id='name-input' value={name} onValueChanged={onNameChanged} title={'Full Name'} placeholder='Enter Name' />
                <Typography variant='h4' style={{ textAlign: 'left', paddingBottom: '15px', width: '100%' }}>
                    Email
                </Typography>
                <EmailInput hideIcon onEnterPressed={onSignUpPressed} disabled={!!user} value={user?.email || email} onValueChanged={onEmailChanged} error={emailError} />
                {!user &&
                    <>
                        <span style={{ minHeight: '30px' }} />
                        <Typography variant='h4' style={{ textAlign: 'left', paddingBottom: '15px', width: '100%' }}>
                            Password
                        </Typography>

                        <PasswordInput hideIcon onEnterPressed={onSignUpPressed} placeholder='Enter password' value={password} onValueChanged={onPasswordChanged} error={passwordError} />
                    </>
                }
                {/* <StyledCheckRow onClick={() => setMarketingChecked(!marketingChecked)}>
                    <Checkbox
                        disableRipple
                        checked={marketingChecked}
                        color='secondary'
                        sx={{
                            width: '18px',
                            height: '30px',
                            marginRight: '14px',
                            color: AppColors.grey.neutral,
                            '&.Mui-checked': {
                                color: AppColors.pink.dark,
                            },
                        }} />
                    <Typography variant='h5' style={{ lineHeight: '24px', color: AppColors.grey.darkish, display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', textAlign: 'left' }}>
                        Yes! Keep me informed with tips, best practices, and new features for my PathPilot journey.
                    </Typography>
                </StyledCheckRow> */}
                {error &&
                    <>
                        <span style={{ minHeight: '20px', maxHeight: '20px' }} />
                        <Typography variant='subtitle2' style={{ color: AppColors.error }}>
                            {error}
                        </Typography>
                    </>
                }
                {loading && <>
                    <span style={{ minHeight: '20px', maxHeight: '20px' }} />
                    <Bars
                        height="30"
                        width="30"
                        color={AppColors.pink.dark}
                        ariaLabel="bars-loading"
                        visible={true}
                    />
                </>}
            </StyledScrollingContainer>
            <StyledButtons isMobile={isMobile}>
                <PrimaryButton style={{ width: '100%', margin: isMobile ? '0px 24px' : undefined }} title={t(Strings.createAccount).toUpperCase()} disabled={!buttonEnabled()} onClick={onSignUpPressed} />
            </StyledButtons>
        </StyledContainer>
    )
}

export default SignUpInfoScreen;