import styled from "@emotion/styled";
import { InterviewQuestionsSelectedAction } from "../../redux/actions/InterviewActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import SelectionPill from "../../shared/components/SelectionPill";
import PrimaryButton from "../../shared/components/PrimaryButton";
import { setAdvancedInterviewActive, setVoiceInterviewActive } from "../../redux/reducers/appReducer";
import { InterviewState } from "../../shared/models/Interview";
import { InterviewMode } from "../../shared/enums/InterviewType";

const StyledRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: '100%',
    gap: '15px',
}));

interface StartVoiceInterviewButtonProps {
    mode: InterviewMode;
}

const StartVoiceInterviewButton = (props: StartVoiceInterviewButtonProps) => {
    const dispatch = useAppDispatch();
    const activeChat = useAppSelector(store => store.appState.activeChat);
    if (activeChat?.pending || activeChat?.streaming || activeChat?.interview?.state === InterviewState.completed) return null;

    return (
        <StyledRow>
            <PrimaryButton onClick={() => dispatch(props.mode === InterviewMode.voice ? setVoiceInterviewActive(true) : setAdvancedInterviewActive(true))} title={(activeChat?.interview?.answers?.length ?? 0) > 0 || activeChat?.interview?.state === InterviewState.started ? "Continue Interview" : "Start Interview"} />
        </StyledRow>
    )
}

export default StartVoiceInterviewButton;